import classNames from 'classnames'
import React from 'react'
import { Input as InputComponent } from '@clout-team/web-components'
import './Input.scss'

interface IInput {
  id?: string
  value: string
  onChange: (e?: any) => void
  onPaste?: (e?: any) => void
  fullWidth?: boolean
  placeholder?: string
  className?: string
  title?: string
  focus?: boolean
  onBlurHandler?: (e: any) => void
  onKeyDown?: (e?: any) => void
  capitalizeValue?: boolean
  errorMessage?: string
  dismiss?: boolean
  type?: 'text' | 'number' | 'tel' | 'email' | 'password' | 'textarea'
  inputBg?: boolean
  description?: string
  focusDelay?: number
  onDismissClick?: () => void
  maxLength?: number
}

const Input: React.FC<IInput> = (props) => {
  const {
    id,
    value,
    errorMessage = '',
    onBlurHandler,
    onChange,
    onPaste,
    fullWidth,
    placeholder,
    className,
    title,
    focus,
    onKeyDown,
    type = 'text',
    capitalizeValue = false,
    dismiss = true,
    inputBg,
    description,
    onDismissClick,
    focusDelay,
    maxLength,
  } = props

  const mainStyles = classNames(
    'inp_wrapper',
    { inp_full_width: fullWidth },
    className && className,
  )

  return (
    <div className={mainStyles}>
      {title && <p className={'inp_title'}>{title}</p>}
      <InputComponent
        description={description}
        dismiss={dismiss}
        id={id}
        onDismissClick={onDismissClick}
        errorMessage={errorMessage}
        type={type}
        capitalizeValue={capitalizeValue}
        className={'inp_auth_input'}
        focus={focus}
        value={value}
        onPaste={onPaste}
        focusDelay={focusDelay}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        onBlur={(e: any) => {
          if (onBlurHandler) {
            onBlurHandler(e)
          }
        }}
        inputBg={inputBg}
      />
    </div>
  )
}
export default Input
