import { Layout } from '@clout-team/web-components'
import { BRAND_NAME } from '../../const/constants'
import React from 'react'
import { ReactComponent as LenzaIcon } from '../../../assets/images/lenzaLogoMob.svg'
import './Logo.scss'

interface LogoProps {
  logo?: string
}
const Logo = (props: LogoProps) => {
  const { logo = BRAND_NAME } = props

  return (
    <Layout gap={'sm'} isAlignCenter className={'logo'}>
      <LenzaIcon className={'logo_icon'} />
      <h1 className={'logo_text'}>{logo}</h1>
    </Layout>
  )
}

export default Logo
