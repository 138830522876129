import { Button, InviteUsersInCompany } from '@clout-team/web-components'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
  createInviteLink,
  inviteAdmins,
  inviteUsers,
  skipInviteUsers,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { binarySearch } from '../../shared/const/emails'
import { useLoginActions } from '../../state/slices/login'
import Check from './Check/Check'
import CopyLink from './CopyLink/CopyLink'
import './InviteUsers.scss'
import { getDomainFromEmail, isCorpEmail } from '../../shared/lib/functions'
import { isMobile } from '@clout-team/helpers'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'

export const InviteUsersWeb: React.FC = () => {
  const { t } = useTranslation()

  const { setCorpDomains } = useLoginActions()

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [validatedItems, setValidatedItems] = useState<number>(0)
  const dispatch = useAppDispatch()
  const hashReg = useAppSelector((state) => state.login.hash_registration)
  const email = useAppSelector((state) => state.login.mail)
  const inviteLink = useAppSelector((state) => state.login.invite_link)
  const isMyDomainCorp = useAppSelector((state) => state.login.is_my_domain_corp)
  const isDomainInvite = useAppSelector((state) => state.login.is_domain_invite)
  const corpDomains = useAppSelector((state) => state.login.corpDomains)

  const [isInviteUsersMenuOpen, setInviteUsersMenuOpen] = useState(true)

  const isValidate = (el: any) => el.isValid && !el.isExists

  const onSendInvites = async () => {
    debugger
    if (isSuccess) {
      await dispatch(inviteAdmins({ skip: true, hash_registration: hashReg }))
      const validItems = items.map((el) => {
        if (isValidate(el)) {
          return el.title
        }
        return
      })

      await dispatch(
        inviteUsers({ emails: [...validItems], skip: false, hash_registration: hashReg }),
      )
      return
    }

    if (!isSuccess && validatedItems > 0) {
      const validItems = items.map((el) => {
        if (isValidate(el)) {
          return getDomainFromEmail(el.title)
        }
        return
      })

      const uniqueItems = validItems
        .filter((element, index) => {
          return validItems.indexOf(element) === index
        })
        .filter((el) => {
          const res = binarySearch(el)
          return res === -1 && el !== undefined
        })

      setCorpDomains(uniqueItems)
      setIsSuccess(true)
    }
  }

  const onSkip = async () => {
    debugger
    if (isSuccess) {
      setIsSuccess(false)
      setInviteUsersMenuOpen(true)
      return
    }

    if (!isSuccess) {
      await dispatch(inviteAdmins({ skip: true, hash_registration: hashReg }))
      await dispatch(skipInviteUsers({ hash_registration: hashReg }))
    }
  }

  useEffect(() => {
    dispatch(createInviteLink({ hash_registration: hashReg }))
  }, [])

  const handleContinueButton = async () => {
    debugger
    return false
  }

  return (
    <div className={'inu_invite'}>
      <h2 className={'inu_invite__heading'}>
        {isSuccess
          ? `${t('auth.new.invite_users.your_invitation')?.replace('{__items__}', validatedItems?.toString())}`
          : `${t('auth.new.invite.title')}${validatedItems > 0 ? ' ' + validatedItems : ''}`}
      </h2>

      {isSuccess && (
        <p className={'inu_invite__description'}>
          {t('auth.description.you_invited')?.replace(
            '{__members__}',
            ` ${validatedItems} ${t('auth.description.members')}`,
          )}
        </p>
      )}

      <div
        className={classNames('inu_invite__wrapper', {
          inu_invite__wrapper_success: isSuccess,
        })}
      >
        {isInviteUsersMenuOpen && (
          <InviteUsersInCompany
            version={1}
            isUserInvite={false}
            isSuccess={isSuccess}
            onChangeValidItems={(count: number) => {
              setValidatedItems(count)
            }}
            handleContinueButton={handleContinueButton}
            onModalClose={() => {
              if (isMobile()) setInviteUsersMenuOpen(false)
            }}
            onChangeItems={(val: any) => setItems(val)}
            hashRegistration={hashReg}
          />
        )}

        {!isSuccess ? (
          <>
            <CopyLink copyTarget={inviteLink as string} />
            {email && isCorpEmail(email) && (
              <div className={'inu_invite__check_wrapper'}>
                <Check isMyDomain corpDomains={[getDomainFromEmail(email)]} />
              </div>
            )}
          </>
        ) : (
          <div className={'inu_invite__check_wrapper'}>
            <Check corpDomains={corpDomains} />
          </div>
        )}
      </div>

      <div className={'inu_invite__buttons'}>
        {isMyDomainCorp && validatedItems === 0 ? (
          <Button
            size={'lg'}
            className={'inu_invite__buttons_send'}
            fullWidth
            onClick={onSkip}
          >
            {t('auth.new.done')}
          </Button>
        ) : (
          <Button
            size={'lg'}
            disabled={validatedItems === 0}
            className={'inu_invite__buttons_send'}
            fullWidth
            onClick={onSendInvites}
          >
            {isSuccess ? t('auth.new.done') : t('auth.new.send')}
          </Button>
        )}

        {isDomainInvite ? null : (
          <Button
            size={'lg'}
            color={'white'}
            inverted={!isSuccess}
            fullWidth
            onClick={onSkip}
          >
            {isSuccess ? t('auth.new.invite_more') : t('auth.new.invite_later')}
          </Button>
        )}
      </div>
    </div>
  )
}
