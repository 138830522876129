import { LANG_ARR } from '../../const/lang'

export function getSystemLang() {
  const osLang = navigator.language

  if (osLang.includes('ru')) {
    return 'ru'
  }

  if (LANG_ARR.includes(navigator.language)) return navigator.language

  return 'en'
}
