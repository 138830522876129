import React from 'react'
import { Dialog } from '@clout-team/web-components'

const DialogAgreement = (props: {
  L: (s: string) => void,
  onClose: () => void
}) => {
  const { L, onClose } = props

  return (
    <Dialog
      className={'dialog-auth-privacy'}
      closeOnClickOutside={false}
      dialogTitle={{
        title: L('auth.privacy.user.agree'),
        varOnlyTitle: false,
      }}
      onClose={onClose}
      closeOnEsc
      size={'lg'}
      isScrollContent
    >
      <strong><p style={{ margin: 'margin: 20px 0px 20px' }}>Заявление о конфиденциальности Lenza OS</p></strong>
      <p>Настоящее Заявление о конфиденциальности описывает типы собираемых и/или обрабатываемых (включая сбор,
        организацию, структурирование, хранение, использование или раскрытие) нами персональных данных при
        предоставлении продуктов и оказании услуг, предлагаемых непосредственно компанией Mailchat Inc., включая
        веб-сайты компании Mailchat Inc., а также ее конференции, вебинары, платформу, предназначенную для обмена
        сообщениями, связанные функции для коллективной работы Lenza OS (далее — «продукты и услуги Lenza OS» или
        «продукты и услуги»). Продукты и услуги Lenza OS, которые охватывает настоящее Заявление о конфиденциальности,
        не включают разработанные компанией Mailchat Inc. продукты или услуги, которые охватывает какая-либо отдельная
        политика конфиденциальности.</p><p>* Какие персональные данные мы получаем?</p><p>* Как мы используем
      персональные данные?</p><p>* Как мы передаем персональные данные?</p><p>* Кто может просматривать и обрабатывать
      мои персональные данные, а также разглашать их, когда я присоединяюсь к конференциям и использую другие продукты и
      услуги Lenza OS?</p><p>* Дети</p><p>* Наши контактные данные</p><p>* Хранение данных</p><p>* Права на
      конфиденциальность в Калифорнии, США</p><p>* Изменения настоящего Заявления о конфиденциальности</p><p>Какие
      персональные данные мы получаем?</p><p></p><p>Персональные данные — это любая информация, получаемая от
      идентифицированного или идентифицируемого лица, а также информация о соответствующем лице, включая информацию,
      которую Lenza OS может связать с отдельным лицом. Мы можем собирать или обрабатывать от имени наших клиентов
      перечисленные ниже категории персональных данных при использовании вами продуктов и услуг Lenza OS или
      взаимодействии с ними.</p><p>* Информация из учетной записи: связанная с учетной записью, лицензирующей продукты и
      услуги Lenza OS, информация, которая может включать имя администратора, контактную информацию, идентификатор
      учетной записи, платежную информацию, а также информацию о транзакциях и о тарифном плане учетной записи.</p><p>*
      Информация об участнике и профиле: информация, связанная с профилем Lenza OS пользователя, который использует
      продукты и услуги Lenza OS под лицензированной учетной записью, или информация, которая предоставляется
      нелицензированным участником, присоединяющимся к конференции, и которая может включать в себя имя, отображаемое
      имя, изображение, адрес электронной почты, номер телефона, информацию о должности, определенное местонахождение,
      идентификатор пользователя или другую информацию, предоставленную пользователем и/или владельцем его учетной
      записи.</p><p>* Интеграция контактов и календаря: контактная информация, которая добавляется учетными записями
      и/или их пользователями для создания списков контактов в рамках продуктов и услуг Lenza OS и которая может
      включать контактную информацию, интегрируемую пользователем из стороннего приложения или предоставленную
      пользователями для обработки реферальных приглашений. Кроме того, пользователи могут интегрировать свои календари
      из других служб с помощью профиля или учетной записи Lenza OS.</p><p>* Информация электронной почты: информация из
      электронной почты, включая содержимое электронных сообщений, заголовки и метаданные, если владельцы учетной записи
      и/или их пользователи интегрируют их адреса электронной почты с продуктами и услугами, которые предлагает или к
      которым дает доступ компания Lenza OS.</p><p>* Настройки: информация, связанная с предпочтениями и настройками
      учетной записи или профиля пользователя Lenza OS, включая настройки аудио и видео, расположение осуществления
      записи файла, настройки демонстрации экрана, а также прочие настройки и информацию о конфигурации.</p><p>*
      Регистрационная информация: информация, которую люди предоставляют при регистрации на конференциях и вебинарах
      Lenza OS или для доступа к записи, которая может содержать имя, контактную информацию, ответы на вопросы,
      задаваемые в ходе регистрации, а также другую регистрационную информацию, запрашиваемую организатором.</p><p>*
      Информация об устройстве: информация о компьютерах, телефонах и других устройствах, используемых при
      взаимодействии с продуктами и услугами Lenza OS, которая может включать информацию о динамиках, микрофоне и
      камере, версии ОС, идентификаторе жесткого диска, имени компьютера, MAC-адресе, IP-адресе (который может
      использоваться для выявления общего расположения на уровне города или страны), свойствах устройства (таких как
      версия операционной системы и уровень заряда аккумулятора), информацию о сети Wi-Fi, а также другую информацию об
      устройстве (такую как сигналы Bluetooth).</p><p>* Содержимое и контекст из конференций, вебинаров, обмена
      сообщениями и других функций для коллективной работы:&nbsp;содержимое, созданное на конференциях, вебинарах или в
      сообщениях, размещаемых в рамках продуктов или услуг Lenza OS. Это содержимое может включать аудио, видео,
      сообщения, отправляемые на конференции, доски сообщений, используемые как на конференции, так и за ее пределами,
      содержимое сообщений чата, расшифровки, изменения расшифровок и рекомендации, обратную связь в письменном виде,
      ответы на опросы и вопросы и ответы, файлы, а также связанный контекст, например, данные приглашений, название
      конференции или чата либо повестка дня конференции. Содержимое может включать ваш голос и изображение в
      зависимости от настроек владельца учетной записи, ваших настроек, в том числе настроек ваших предпочтений, а также
      осуществляемых вами с использованием продуктов и услуг Lenza OS действий.</p><p>* Использование продуктов и
      веб-сайта:&nbsp;информация о том, как пользователи и их устройства взаимодействуют с продуктами и услугами Lenza
      OS, например когда участники присоединяются к конференции и покидают ее, отправляли ли участники сообщения и с кем
      они общались, данные о производительности, движения мыши, щелчки мыши, нажатия клавиш или действия (такие как
      включение и выключение звука или видео), изменения, внесенные в текст расшифровки, если это разрешено владельцем
      учетной записи, и ввод пользователем других данных, которые помогают Lenza OS понять, как используются функции,
      как улучшить дизайн продукта и какие функции предложить, какие сторонние приложения добавляют к конференции либо
      другому продукту или услуге, к какой информации приложение имеет доступ и какие действия выполняет, как
      используются сторонние приложения Lenza OS, как используются функции (например, демонстрация экрана, эмодзи или
      фильтры), а также другая информация и показатели использования. Указанное также включает в себя информацию о том,
      когда и как пользователи посещают веб-сайты Lenza OS и взаимодействуют с ними, в том числе о том, какие страницы
      они посещают, а также об их взаимодействии с функциями веб-сайта и о том, есть ли у них подписка на продукты или
      услуги Lenza OS.</p><p>* Коммуникации с Lenza OS: информация о ваших коммуникациях с Lenza OS, включая вопросы,
      относящиеся к поддержке, к вашей учетной записи и другие.</p><p>* Информация от партнеров: Lenza OS получает
      информацию о владельцах учетных записей и их пользователях от сторонних компаний, таких как компании, оказывающие
      услуги по сбору маркетинговых данных, в том числе информацию о размере компании владельца учетной записи или об
      отрасли, к которой она относится, о контактной информации или активности определенных корпоративных доменов. Lenza
      OS также может получать информацию от сторонних рекламных партнеров, которые размещают рекламу в рамках продуктов
      и услуг Lenza OS, например, о том, переходили ли вы по отображаемой рекламной ссылке.</p><p>Как мы используем
      персональные данные?</p><p>Сотрудники Lenza OS не имеют доступа к содержимому конференций, вебинаров или обмена
      сообщениями (в частности, аудио, видео, файлам, доскам сообщений на конференциях и сообщениям) и какому-либо
      содержимому, созданному или предоставленному в совместное пользование в рамках других функций для коллективной
      работы (например, доски сообщений за пределами конференций или электронные сообщения), если на то не было получено
      прямое разрешение владельца учетной записи или если это не предусмотрено правилами в целях обеспечения законности,
      охраны или безопасности согласно указанным ниже положениям. Lenza OS использует персональные данные для
      осуществления перечисленных ниже действий.</p><p>* Предоставление продуктов и услуг Lenza OS:&nbsp;для
      предоставления продуктов и услуг владельцам и пользователям учетных записей, а также тем, кого они приглашают
      присоединиться к конференциям и вебинарам, организуемым с помощью их учетных записей, включая настройку продукта и
      услуг Lenza OS и рекомендации для учетных записей или их пользователей. Lenza OS также использует персональные
      данные, включая контактную информацию, для маршрутизации приглашений и сообщений получателям при отправке
      пользователями приглашений и сообщений с помощью продуктов и услуг Lenza OS. Указанное также может включать
      использование персональных данных для оказания поддержки клиентам, в том числе предоставления доступа к аудио,
      видео, файлам, сообщениям и другому содержимому или контексту, по указанию владельца учетной записи или его
      пользователей. Мы также используем персональные данные для управления нашими взаимоотношениями и договоренностями
      с владельцами учетных записей и другими лицами, включая выставление счетов, соблюдение договорных обязательств,
      упрощение оплаты для сторонних разработчиков в связи с покупками, совершенными посредством Lenza OS, и другие виды
      администрирования в связи с этим.</p><p>o Расширенные функции голосовой связи и видеосвязи: если вы решите
      использовать определенные функции видеосвязи, такие как фильтры, аватары и жесты, для применения выбранных функций
      на вашем устройстве может обрабатываться информация о ваших движениях или положении лица либо рук. Эти данные не
      покидают вашего устройства, не сохраняются и не могут быть использованы для установления вашей личности. Если
      определенные функции включены, как, например, создание расшифровки для записей, Lenza OS может использовать
      технологию, анализирующую запись аудио конференции, чтобы отличать говорящих пользователей друг от друга с целью
      создания точной расшифровки. После создания расшифровки данные анализа аудио не сохраняются.</p><p>* Исследования
      и разработка продуктов:&nbsp;для разработки, тестирования и усовершенствования продуктов и услуг Lenza OS,
      включая, например, функции для работы с содержимым (например, фон и другие фильтры), а также для устранения
      неполадок в продуктах и услугах.</p><p>* Маркетинг, продвижение и сторонняя реклама:&nbsp;чтобы разрешить Lenza OS
      и/или сторонним рекламным партнерам предлагать, рекламировать и продвигать продукты и услуги Lenza OS, включая
      основанные на вашем использовании продукта или на информации, которую мы получаем от сторонних партнеров,
      информации, которую вы предоставляете для обработки реферальных приглашений, либо данных о посещении вами наших
      веб-сайтов, информации о дате и способе посещения, а также о ваших взаимодействиях с веб-сайтом. Кроме того, мы
      можем использовать эту информацию для предоставления вам рекламы, связанной с продуктами и услугами Lenza OS, или
      для привлечения сторонних партнеров с целью анализа ваших взаимодействий на нашем веб-сайте или в приложении либо
      для доставки вам рекламы. Сотрудники Lenza OS не используют содержимое конференций, вебинаров или обмена
      сообщениями (в частности, аудио, видео, совместно используемые файлы, доски сообщений на конференциях и сообщения)
      и какое-либо содержимое, созданное или предоставленное в совместное пользование в рамках других функций для
      коллективной работы (например, доски сообщений за пределами конференций или электронные сообщения), для
      какого-либо маркетинга или рекламы.</p><p>* Аутентификация, целостность, безопасность и защита:&nbsp;для
      аутентификации учетных записей и действий, для обнаружения, расследования и предотвращения вредоносного поведения
      или небезопасных действий, устранения угроз безопасности, охраны общественной безопасности и защиты продуктов и
      услуг Lenza OS.</p><p>* Связь с вами:&nbsp;мы используем персональные данные (включая контактную информацию) для
      связи с вами по поводу продуктов и услуг Lenza OS, в том числе обновления продуктов, по поводу вашей учетной
      записи и изменений в наших политиках и условиях использования. Мы также используем вашу информацию, чтобы
      предоставлять вам ответы на ваши запросы, направляемые в наш адрес.</p><p>* Правовые основания:&nbsp;в целях
      соблюдения подлежащего применению законодательства, реагирования на действующую юридическую процедуру, в том числе
      со стороны правоохранительных органов или государственных учреждений, расследования или участия в гражданском
      раскрытии документов, судебном или другом состязательном разбирательстве, а также для обеспечения соблюдения или
      расследования потенциальных нарушений наших Условий обслуживания или политик.</p><p>Lenza OS использует
      расширенные инструменты автоматического сканирования контента, например, виртуальных фонов, изображений профилей и
      файлов, загружаемых или передаваемых через чат, с целью обнаружения и предотвращения нарушений наших условий или
      политик, а также незаконных или иных вредоносных действий, при этом сотрудники Lenza OS имеют право просматривать
      соответствующий контент, если это необходимо по юридическим причинам или по соображениям безопасности.</p><p></p>
      <p></p><p>Как мы передаем персональные данные?</p><p>Lenza OS предоставляет персональные данные третьим лицам
      только при получении соответствующего согласия или при возникновении одного из перечисленных ниже обстоятельств
      (при условии получения вашего предварительного согласия, если это предусмотрено действующим
      законодательством).</p><p>* Партнеры по продажам:&nbsp;если владелец учетной записи лицензировал или приобрел
      продукты и услуги Lenza OS у стороннего партнера по продажам продуктов и услуг Lenza OS, этот партнер может иметь
      доступ к персональным данным и контенту пользователей, включая размещаемые владельцем учетной записи сообщения и
      организованные им конференции и вебинары.</p><p>* Поставщики:&nbsp;Lenza OS работает со сторонними поставщиками
      услуг для предоставления, поддержки и улучшения продуктов и услуг Lenza OS, а также технической инфраструктуры и
      предоставления бизнес-услуг, таких как обработка платежей, в том числе связанных с покупками, совершенными
      посредством Lenza OS. Lenza OS также может сотрудничать со сторонними поставщиками услуг в целях проведения
      рекламных кампаний и предоставления бизнес-аналитики в отношении продуктов и услуг Lenza OS. Соответствующие
      поставщики могут получать доступ к персональным данным при условии соблюдения договорных и технических требований,
      принятых в целях защиты персональных данных, а также при условии соблюдения запрета на использование персональных
      данных для любых целей, кроме предоставления услуг Lenza OS, а также в соответствии с требованиями закона. Lenza
      OS может интегрировать сторонние технологии для предоставления расширенных функций, например, технологию TrueDepth
      от Apple, с целью обработки информации о размерах лица и рук и жестах с устройства для применения видеоэффектов.
      Эта информация обрабатывается на вашем устройстве, но ни какая-либо третья сторона, ни компания Lenza OS не
      получает и не хранит такую информацию.</p><p>* Правовые основания:&nbsp;Lenza OS может обмениваться персональными
      данными по мере необходимости для: (1) соблюдения применимого законодательства или реагирования, расследования или
      участия в действующей юридической процедуре и процессуальных действиях, в том числе совершаемых
      правоохранительными органами или государственными учреждениями; (2) обеспечения соблюдения или расследования
      возможных нарушений Условий обслуживания или политик; (3) обнаружения, предотвращения или расследования
      потенциальных угроз мошенничества, злоупотребления или проблем защиты и безопасности, включая угрозы для общества;
      (4) выполнения наших обязательств в области корпоративной и социальной ответственности; (5) защиты наших прав и
      имущества, а также прав и имущества наших клиентов; (6) разрешения споров и обеспечения соблюдения соглашений.</p>
      <p>* Партнеры по маркетингу, рекламе и аналитике:&nbsp;Lenza OS использует сторонних поставщиков услуг маркетинга,
        рекламы и аналитики для предоставления статистики и аналитики об использовании людьми продуктов и услуг Lenza
        OS, в том числе нашего веб-сайта, и для предоставления услуг маркетинга и рекламы продуктов и услуг Lenza OS,
        включая таргетированную рекламу, базирующуюся на использовании вами нашего веб-сайта. Соответствующие сторонние
        партнеры могут получать информацию о ваших действиях на веб-сайте Lenza OS через сторонние файлы cookie,
        размещенные на веб-сайте Lenza OS. Если вы хотите запретить использование сторонних файлов cookie, передающих
        данные соответствующим партнерам, откройте средство управления файлами cookie в настройках файлов cookie. В
        предусмотренных законом случаях компания Lenza OS обязана получить ваше предварительное согласие, прежде чем
        приступать к описанным здесь маркетинговым и рекламным действиям.</p><p>* Изменение Контроля:&nbsp;Мы можем
      делиться персональными данными с фактическими или потенциальными покупателями, их представителями и другими
      участниками или в ходе переговоров о любой продаже, слиянии, приобретении, реструктуризации или изменении
      контроля, связанного со всем бизнесом или активами Lenza OS либо их частью, в том числе в связи с банкротством или
      иными аналогичными процедурами.</p><p>* Сторонние разработчики:&nbsp;если вы приобретаете стороннее приложение или
      интеграцию в Lenza OS, компания Lenza OS может передавать информацию о покупке стороннему разработчику с целью
      предоставления вам указанного приложения или интеграции.</p><p>Кто может просматривать и обрабатывать мои
      персональные данные, а также разглашать их, когда я присоединяюсь к конференциям и использую другие продукты и
      услуги Lenza OS?</p><p>При отправке вами сообщений или при присоединении к конференциям и вебинарам в Lenza OS
      иные лица и организации, в том числе не участвующие в конференции, вебинаре или обмене сообщениями третьи лица,
      могут видеть направляемые вами контент и информацию:</p><p>* Владелец учетной записи:&nbsp;владелец учетной
      записи&nbsp;— это юридическое или физическое лицо, зарегистрировавшее учетную запись Lenza OS. Как правило,
      владелец учетной записи назначает одного или нескольких человек (именуемых «администраторами») для управления
      своей учетной записью, а также может предоставлять права пользователям соответствующей учетной записи. В
      зависимости от типа лицензии Lenza OS владелец учетной записи имеет право авторизовать дополнительных
      пользователей для использования своей учетной записи; кроме того, владелец учетной записи может вносить информацию
      в профиль для всех пользователей своей учетной записи и/или получать доступ к соответствующей информации. Владелец
      учетной записи и ее пользователи могут приглашать других лиц (включая гостей, не имеющих доступа к соответствующей
      учетной записи, или участников без лицензии) для участия в конференциях или вебинарах, организуемых с
      использованием соответствующей учетной записи.</p><p>Lenza OS предоставляет владельцам учетных записей элементы
      управления и функции, которые могут быть использованы для определения возможности создания или отправки
      определенного типа контента, например, записей или сообщений, направляемых лицами вне конференций, а также
      перечень сторонних приложений, которые можно использовать для проведения конференций и вебинаров, организуемых с
      использованием соответствующей учетной записи. В зависимости от применимых настроек владельцы учетной записи и
      назначенные ими пользователи могут получать доступ к персональным данным участников, присоединяющихся к
      конференциям и вебинарам, организуемым с использованием соответствующей учетной записи владельца, а также
      отправляющих сообщения пользователям, привязанным к соответствующей учетной записи. В частности, владельцы учетных
      записей могут иметь доступ к перечисленному ниже.</p><p></p><p>o Информация об использовании учетной записи:</p>
      <p>o Использование продукта:&nbsp;информация о том, как пользователи и их устройства взаимодействуют со своей
        учетной записью, которая может включать сведения о том, кто отправлял сообщения своим пользователям в чате,
        адреса электронной почты, IP-адреса, информацию об устройстве и другую информацию о том, кто участвовал в
        конференциях или вебинарах в их учетной записи, просматривали и загружали ли пользователи запись, как долго
        пользователи участвовали в их конференциях, информацию о времени отправки сообщения и другую информацию об
        использовании и показатели обратной связи.</p><p>o Список участников:&nbsp;информация об участниках конференции,
      вебинара или чата Lenza OS, которая может включать имя, отображаемое имя, адрес электронной почты, номер телефона
      и идентификатор участника или пользователя.</p><p>o Регистрационная информация:&nbsp;информация, предоставляемая в
      ходе регистрации на вебинар или конференцию, в Lenza OS или для получения доступа к записи, которые размещены в
      учетной записи.</p><p>o Сообщения в Lenza OS вне конференции:&nbsp;если в учетной записи включена такая
      возможность, владельцы учетной записи и те, кому они предоставят разрешения, могут просматривать информацию о том,
      кто отправляет пользователям и получает сообщения вне конференции в их учетной записи вместе с информацией о
      сообщении (например, дата и время, а также количество участников). В зависимости от применимых настроек владельцы
      учетных записей также могут просматривать информацию об отправителях и получателях и иные данные об обмене
      сообщениями, а также видеть содержимое сообщений, отправленных в адрес пользователей или от их имени с
      использованием их учетных записей, если владелец учетной записи не включил функцию расширенное шифрование чата. В
      зависимости от настроек владельцы учетной записи и те, кому они предоставят разрешение, могут также просматривать
      содержимое, предоставленное в совместное пользование с помощью функций для коллективной работы, включая доски
      сообщений, файлы и изображения, предоставленные в чате за пределами конференции.</p><p>o Сообщения в ходе
      конференции или вебинара:&nbsp;в зависимости от настроек владельцы учетных записей могут просматривать информацию
      об отправителе и получателе вместе с содержимым сообщений, переданных от пользователей и в их адрес в своей
      учетной записи, в следующих обстоятельствах:</p><p>o сообщения, отправленные всем участникам записываемой
      конференции;</p><p>o сообщения, отправленные участникам группы в рамках записываемого вебинара;</p><p>o
      направляемые напрямую сообщения, если владелец учетной записи включил функцию архивирования.</p><p>o Если участник
      конференции подлежит архивации, владелец его учетной записи получит доступ к сообщениям, отправленным всем
      пользователям на конференции, а также к личным сообщениям, отправленным этому участнику.</p><p>o
      Записи:&nbsp;владельцы учетных записей могут просматривать записи конференций и вебинаров, размещенных в их
      учетных записях. Они также могут просматривать, предоставлять и включать расширенные функции для создания
      расшифровки аудиочасти конференции.</p><p>o Опросы, вопросы и ответы и обратная связь: владельцы учетных записей
      могут просматривать информацию о том, кто размещает ответы в их опросах, на сеансах вопросов и ответов или
      публикует запросы об обратной связи на конференции или вебинаре, включая имя и контактную информацию, вместе с
      ответами или обратной связью, кроме случаев, когда они были размещены анонимно.</p><p>* Организаторы конференций,
      участники и приглашенные:&nbsp;организаторы, участники и приглашенные могут просматривать ваш адрес электронной
      почты, отображаемое имя и изображение профиля. Организаторы конференций, участники и приглашенные также могут
      просматривать и (в зависимости от настроек владельца учетной записи) записывать или сохранять содержимое
      конференции, расшифровки аудио, сообщения, отправленные всем или им непосредственно, а также файлы, доски
      сообщений или иную информацию, которая распространяется в ходе конференции. Организаторы также могут просматривать
      ответы на вопросы в разделе «Вопросы и ответы» и опросы, которые были созданы во время конференции.</p><p>*
      Участники группы и присутствующие на вебинаре:&nbsp;присутствующие в ходе вебинара могут видеть только участников
      группы, при этом присутствующих, которые включили звук, могут слышать другие присутствующие. Если слушатель
      соглашается стать участником дискуссии во время вебинара, он может быть виден другим участникам в зависимости от
      применимых настроек. Участники группы и присутствующие могут видеть имя участника, задавшего вопрос во время
      сеанса вопросов и ответов, вместе с текстом соответствующего вопроса, если вопрос не был отправлен участником
      анонимно.</p><p>* Прямые трансляции:&nbsp;организаторы конференции и вебинара могут выбрать для прямой трансляции
      сторонний сайт или службу. Это значит, что любое лицо, обладающее доступом к прямой трансляции, сможет
      просматривать конференцию или вебинар.</p><p>* Приложения и интеграции:&nbsp;&nbsp;</p><p>o Владельцы учетных
      записей могут добавлять приложения, разработанные компанией Lenza OS, и приложения сторонних разработчиков в свои
      учетные записи и используемые ими продукты Lenza OS, в том числе через Lenza OS, а также могут предоставлять своим
      пользователям разрешение на добавление и использование определенных приложений Lenza OS и сторонних приложений, в
      том числе в рамках конференций, вебинаров и чатов, организуемых с использованием соответствующих учетных
      записей.</p><p>o Владельцы учетных записей могут интегрировать другое содержимое, например, электронные сообщения
      в их корпоративной учетной записи, в используемые приложения и услуги, такие как Lenza OS (разработанное компанией
      Lenza OS приложение, которое предоставляет аналитические сведения и бизнес-аналитику в связи с компаниями, когда
      они используют продукты Lenza OS). В дальнейшем владельцы учетных записей могут разрешить Lenza OS анализировать
      запись аудио конференции, чтобы отличать говорящих пользователей друг от друга с целью создания точной
      расшифровки. После создания расшифровки данные анализа аудио не сохраняются.</p><p>o В зависимости от настроек
      персональные данные и содержимое владельцев учетных записей, их пользователей и гостей могут быть предоставлены
      приложениям и интеграциям, утвержденным соответствующими владельцами учетных записей, что может включать в себя
      все перечисленные выше категории персональных данных, в том числе информацию учетной записи и профиля, контактную
      и регистрационную информацию, список участников, настройки, содержимое, сведения об использовании продукта,
      информацию об устройстве или электронные сообщения, отправленные через приложение.</p><p>o Иные участники
      конференции могут видеть используемое вами в рамках конференции приложение, если это приложение получает
      содержимое (включая аудио и видео) из конференции.</p><p>o Сторонние разработчики также могут интегрировать или
      встраивать Lenza OS Meetings в свои веб-сайты или приложения либо создавать версии Lenza OS, обеспечивающие доступ
      к Продуктам Lenza OS из стороннего приложения.</p><p>o Персональные данные, передаваемая владельцами учетных
      записей и пользователями с помощью сторонних приложений и интегрированных решений, собираются и обрабатываются в
      соответствии с условиями и положениями, а также политиками конфиденциальности разработчиков приложений, а не Lenza
      OS.</p><p>Дети</p><p>Lenza OS запрещает детям младше 16 лет регистрировать учетные записи Lenza OS.</p>
      <p>Образовательные организации, использующие продукты и услуги Lenza OS для предоставления образовательных услуг
        детям до 18&nbsp;лет, должны ознакомиться с Заявлением о конфиденциальности Lenza OS в сфере детского
        образования.</p><p>Наши контактные данные</p><p>При наличии связанных с конфиденциальностью вопросов или
      замечаний в отношении настоящего Заявления о конфиденциальности отправьте сообщение электронной почты на
      адрес&nbsp;privacy@lenzaos.com .</p><p>Вы также можете связаться с нами, отправив письмо на следующий адрес:</p>
      <p>Mailchat Inc.</p><p>3101 Park Boulevard,</p><p>Palo Alto, CA 94306</p><p></p><p>Или нашему представителю в
      России:&nbsp;</p><p>107065, г. Москва, ул. Хабаровская, д. 23, корп. 2, кв. 224&nbsp;</p><p>Электронная
      почта:&nbsp;hunets@mail.ru</p><p>Вы можете обратиться к нашему сотруднику по защите данных, отправив сообщение на
      электронную почту&nbsp;privacy@lenzaos.com.</p><p></p><p></p><p></p><p></p><p></p><p></p><p>Хранение данных</p>
      <p>Мы храним персональные данные до тех пор, пока это необходимо для их использования в целях, описанных в
        настоящем Заявлении о конфиденциальности, если действующим законодательством не предусмотрен более длительный
        период хранения.</p><p>Критерии, используемые для определения сроков хранения, включают в себя следующее:</p>
      <p>* срок, в течение которого мы поддерживаем с вами взаимоотношения и предоставляем вам продукты и услуги Lenza
        OS (например, до тех пор, пока у вас есть учетная запись или вы продолжаете использовать наши продукты);</p><p>*
      вносимые владельцами учетных записей или их пользователями изменения, а также удаление информации с использованием
      своих учетных записей;</p><p>* наличие юридических обязательств по хранению данных (например, определенные законы
      требуют от нас вести учет ваших транзакций в течение определенного периода до их удаления);</p><p>*
      целесообразность хранения с учетом нашей правовой позиции (например, необходимость требовать принудительного
      исполнения наших соглашений, разрешение споров, а также применимые сроки давности, судебные разбирательства или
      расследования регулирующих органов).</p><p>Правовые основы для обработки персональных данных</p><p>Мы будем
      использовать ваши данные исключительно в соответствии с законом, а также открытым и справедливым
      способом.&nbsp;</p><p>Международная передача данных</p><p>Lenza OS осуществляет свою деятельность по всему миру.
      Это означает, что персональные данные могут передаваться, храниться (например, в центре обработки данных) и
      обрабатываться за пределами страны или региона, в которых они были изначально получены и в которых компания Lenza
      OS или ее поставщики услуг обслуживают клиентов или имеют объекты, в том числе в странах, в которых проживают или
      находятся участники конференций или владельцы учетных записей, организующие конференции или вебинары, в которых вы
      принимаете участие, а также в странах нахождения получателей отправляемых вами сообщений.</p><p>Поэтому, пользуясь
      продуктами и услугами Lenza OS или предоставляя персональные данные для любой из указанных выше целей, вы
      признаете, что ваши персональные данные могут быть переданы в Соединенные Штаты Америки или в другие страны либо
      могут храниться там. В таких странах могут действовать правила защиты данных, которые отличаются от правил вашей
      страны или являются менее строгими.</p><p>Мы защищаем ваши персональные данные в соответствии с настоящим
      Заявлением о конфиденциальности, где бы эти данные ни обрабатывались, и принимаем соответствующие договорные или
      иные меры для их защиты в соответствии с действующим законодательством.&nbsp;</p><p></p><p></p><p></p><p></p>
      <p>Права на конфиденциальность в Калифорнии, США</p><p>CCPA</p><p>В соответствии с Законом штата Калифорния о
      защите конфиденциальности потребителей (CCPA) от 2018&nbsp;года жители Калифорнии могут иметь право на указанное
      ниже.</p><p>* Доступ&nbsp;к категориям и конкретным видам собранных Lenza OS персональных данных, категориям
      источников, из которых собираются персональные данные, бизнес-цели (целям) сбора персональных данных и категориям
      третьих лиц, которым Lenza OS передает персональные данные.</p><p>* Удаление&nbsp;персональных данных при
      определенных обстоятельствах.</p><p>* Отказ от «продажи»&nbsp;персональных данных. Мы не продаем ваши персональные
      данные в традиционном смысле этого термина. Однако, как и многие компании, мы используем рекламные службы, которые
      стремятся адаптировать онлайн-рекламу к вашим интересам на основе информации, собранной с помощью файлов cookie и
      аналогичных технологий, о ваших действиях в интернете. Это называется рекламой на основе интересов.
      Законодательное определение в CCPA термина «продажа» является широким и может включать в себя рекламу на основе
      интересов. Вы можете получить дополнительную информацию и отказаться от использования на наших сайтах файлов
      cookie, применяемых в целях рекламы на основе интересов, щелкнув ссылку&nbsp;Do Not Sell My Personal Information
      Запрещаю «продавать» мои персональные данные, отменив использование на нашей домашней странице или указав свои
      предпочтения в настройках. Вам будет</p><p>необходимо настроить свои предпочтения на каждом используемом вами
      устройстве и в каждом веб-браузере. Эта функция использует файлы cookie, чтобы запомнить ваши предпочтения,
      поэтому, если вы удалите все файлы cookie в своем браузере, то вам нужно будет заново задать свои настройки.</p>
      <p>Lenza OS не станет ущемлять ваши интересы в связи с реализацией вами любого из указанных прав, что также
        соответствует положениям, изложенным в CCPA.</p><p>Чтобы воспользоваться своими правами позвоните по номеру +1
      669 296 70 29. Чтобы отказаться от использования на наших сайтах файлов cookie для предоставления рекламы на
      основе интересов, следуйте вышеуказанным инструкциям.</p><p>Мы подтвердим получение вашего запроса в течение 10
      рабочих дней и предоставим обоснованный ответ в течение 45 календарных дней или сообщим вам причину задержки в
      предоставлении ответа и укажем крайний срок предоставления ответа (возможна отсрочка до 90 дней) в письменной
      форме.</p><p>Согласно CCPA только вы или уполномоченный агент можете направить запрос, связанный с вашими
      персональными данными. Обратите внимание, что для ответа на ваши запросы на предоставление доступа или удаление
      персональных данных в соответствии с CCPA мы должны подтвердить вашу личность. Для этого мы можем потребовать от
      вас войти в свою учетную запись Lenza OS (если применимо), предоставить информацию, относящуюся к вашей учетной
      записи (которая будет проверена путем сравнения с имеющейся у нас информацией, например, информацией из профиля),
      предоставить заявление с подтверждением вашей личности под угрозой ответственности за лжесвидетельство и/или
      предоставить дополнительную информацию. Вы можете назначить уполномоченного агента для отправки подтвержденного
      запроса потребителя, предоставив письменное разрешение и подтвердив свою личность или направив нам копию
      доверенности.</p><p>Закон штата Калифорния «О гласности»</p><p>Раздел 1798.83 Гражданского кодекса Калифорнии,
      также известный как закон «О гласности», позволяет жителям Калифорнии на ежегодной основе запрашивать информацию,
      касающуюся раскрытия их Персональных данных (если применимо) третьим лицам для целей осуществления третьими лицами
      прямого маркетинга в предшествующем календарном году. Мы не передаем персональные данные третьим лицам в целях
      осуществления третьими лицами прямого маркетинга.</p><p>Изменения настоящего Заявления о конфиденциальности</p>
      <p>Мы имеем право периодически вносить в настоящее Заявление о конфиденциальности изменения в целях отражения
        изменений в сборе и/или обработке нами персональных данных при условии опубликования обновленного Заявления о
        конфиденциальности на нашем веб-сайте вместе с датой «Последнего обновления», указанной вверху страницы. При
        внесении нами существенных изменений в настоящее Заявление о конфиденциальности мы уведомим вас и предоставим
        вам возможность ознакомиться с ними, прежде чем вы решите продолжить использование наших продуктов и услуг.</p>
    </Dialog>
  )
}

export default DialogAgreement
