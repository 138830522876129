export const BRAND_NAME = 'Lenza'
export const BRAND_NAME_RU = 'Ленза'

export const CODE_ERRORS = {
  EXPIRED_CODE: 'LOGIN_0006',
}

// eslint-disable-next-line no-use-before-define
const CURRENT_HOST = location.host
export const IS_LOCALHOST = CURRENT_HOST.includes('localhost:3000')
export const REFERRERS = {
  CHATS: 'chat',
  SPACES: 'spaces',
  CONTACTS: 'contacts',
}

export const UI_TYPE = {
  MAC: 'mac',
  WIN: 'win',
}

export const EXPIRED_CODE_TIME_MS = 180 * 1000

export const SINGLE_THEMES = [
  {
    name: 'PALM',
    // color: { light: 'rgba(102, 96, 209, 1)', dark: '' },
    type: 'colored',
    index: 1,
    key: 'app-theme.1',
  },
  {
    name: 'AUBERGINE',
    // color:'rgba(106, 53, 124, 1)',
    type: 'colored',
    index: 2,
    key: 'app-theme.2',
  },
  {
    name: 'SOFT',
    type: 'classic',
    // color: '#EBEBEB',
    index: 3,
    key: 'app-theme.3',
  },
  {
    name: 'BRIGHT',
    type: 'classic',
    // color: '#FFFFFF',
    index: 4,
    key: 'app-theme.4',
  },
]
