import React from 'react'
import {useSelector} from 'react-redux'
import "./InviteInvalid.scss"
import {Button} from "@clout-team/web-components";
import {useTranslation} from "react-i18next";

const InviteInvalid = () => {
    const {t} = useTranslation();

    const onClickHandler = () => {
        document.location.href = document.location.origin
    }

    return (
        <div className={'inv_invalid'}>
            <h2 className={'inv_invalid__heading'}>{t('auth.new.no_longer_valid.title')}</h2>
            <p className={'inv_invalid__description'}>{t('auth.new.no_longer_valid.description')}</p>
            <Button
                size={'lg'}
                className={'inv_invalid__button'}
                onClick={onClickHandler}
            >{t('auth.new.no_longer_valid.button')}</Button>
        </div>
    )
}

export default InviteInvalid
