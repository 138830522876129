import { Button, Checkbox, config } from '@clout-team/web-components'
import React, { useEffect, useState } from 'react'

import { AddFriendsMob } from './AddFriendsMob'
import { AddFriendsWeb } from './AddFriendsWeb'

const AddFriends: React.FC = () => {
  return config.IS_MOBILE ? <AddFriendsMob /> : <AddFriendsWeb />
}

export default AddFriends
