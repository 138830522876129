import classNames from 'classnames'
import './WrapperMobilePage.scss'
import { useAnyKeyboard } from '@clout-team/web-components'

interface WrapperMobileProps extends React.HTMLProps<HTMLDivElement> {}

export function WrapperMobilePage(props: WrapperMobileProps) {
  const { children } = props

  const isMobileKeyboardActive = useAnyKeyboard()

  return (
    <div
      {...props}
      className={classNames(props.className, 'wrapper-mobile', {
        'wrapper-mobile_active-keyboard': isMobileKeyboardActive,
      })}
    >
      {children}
    </div>
  )
}
