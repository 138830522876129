import React from 'react'
import './CompanyBanner.scss'
import { ReactComponent as ShieldIconMob } from '../../assets/images/shieldIconMob.svg'
import { ReactComponent as ShieldIcon } from '../../assets/images/shieldIcon.svg'
import Header from '../Header/Header'
import { Button, config, Layout, Text } from '@clout-team/web-components'
import { BRAND_NAME } from '../../shared/const/constants'
import Privacy from '../Privacy/Privacy'
import { capitalize } from '../../shared/lib/functions'
import { AuthProfile, useLoginActions } from '../../state/slices/login'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { useTranslation } from 'react-i18next'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import Heading from '../../shared/ui/Heading/Heading'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import { getIsHasProfiles } from '../../state/selectors/getIsHasProfiles'

const CompanyBanner = () => {
  const { t } = useTranslation()

  const companyName = useAppSelector((state) =>
    capitalize(state.login.company_name || 'RDPGROU'),
  )

  const isHasProfiles = useAppSelector(getIsHasProfiles)

  const typeReg = useAppSelector((state) => state.login.type)

  const { setStep } = useLoginActions()

  const onClickHandler = () => {
    if (typeReg === 'INVITE_LINK') {
      setStep('JOIN_COMPANY')
    }

    if (typeReg === 'INVITE_MAIL' || typeReg === 'INVITE_DOMAIN') {
      setStep('PROFILE')
    }
  }

  if (config.IS_MOBILE) {
    const onBack = () => {
      if (isHasProfiles) {
        setStep('WORKSPACE')
      } else {
        setStep('PROMO')
      }
    }

    return (
      <div className={'cb_company'}>
        <MobileHeader onClose={onBack} />

        <div className={'cb_company__heading'}>
          <h1 className={'cb_company__title'}>{t('company-banner.title')} 🎉</h1>
          <p className={'cb_company__description'}>
            {t('company-banner.description')} <b>{companyName?.toUpperCase()}</b>
          </p>
        </div>

        <div className={'cb_company__bottom'}>
          <div className={'cb_company__container'}>
            <ShieldIconMob className={'cb_company__shield_icon'} />
            <h3 className={'cb_company__bottom_title'}>
              {t('auth.new.company_banner.container_title_new')}
            </h3>
            <ul className={'cb_company__list'}>
              <li className={'cb_company__list_item'}>
                {t('auth.new.company_banner.container_list_first')}
              </li>
              <li className={'cb_company__list_item'}>
                {t('auth.new.company_banner.container_list_second_new')?.replace(
                  '{__company__}',
                  companyName?.toUpperCase() || '',
                )}
              </li>
              <li className={'cb_company__list_item'}>
                {t('auth.new.company_banner.container_list_third_new')}
              </li>
            </ul>
            {/*<p className={'cb_company__bottom_description'}>{t('auth.new.company_banner.container_description')}</p>*/}
          </div>
        </div>

        <Layout className='cb_company__submit'>
          <ButtonUI size={'xlg'} fullWidth onClick={onClickHandler}>
            {t('auth.new.company_banner.button')}
          </ButtonUI>
        </Layout>
      </div>
    )
  }

  return (
    <div className={'cb_company'}>
      <Header />
      <div className={'cb_company__heading'}>
        <h1 className={'cb_company__title'}>
          {t('auth.new.company_banner.title_first')}
        </h1>
        <h1 className={'cb_company__title cb_company__title_company'}>
          {t('auth.new.company_banner.title_second')?.replace('{__name__}!', '')}
          <span>{BRAND_NAME.toUpperCase()}</span>! 🎉
        </h1>
        <p className={'cb_company__description'}>
          {t('auth.banner_description')?.replace('{__company__}', companyName || '')}
        </p>
      </div>
      <div className={'cb_company__bottom'}>
        <div className={'cb_company__container'}>
          <ShieldIcon className={'cb_company__shield_icon'} />
          <h3 className={'cb_company__bottom_title'}>
            {t('auth.new.company_banner.container_title_new')}
          </h3>
          <ul className={'cb_company__list'}>
            <li className={'cb_company__list_item'}>
              {t('auth.new.company_banner.container_list_first')}
            </li>
            <li className={'cb_company__list_item'}>
              {t('auth.new.company_banner.container_list_second_new')?.replace(
                '{__company__}',
                companyName?.toUpperCase() || '',
              )}
            </li>
            <li className={'cb_company__list_item'}>
              {t('auth.new.company_banner.container_list_third_new')}
            </li>
          </ul>
          {/*<p className={'cb_company__bottom_description'}>{t('auth.new.company_banner.container_description')}</p>*/}
        </div>
        <Button size={'lg'} fullWidth onClick={onClickHandler}>
          {t('auth.new.company_banner.button')}
        </Button>
      </div>
      <Privacy />
    </div>
  )
}

export default CompanyBanner
