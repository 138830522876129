import { config, Layout, Text } from '@clout-team/web-components'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import { Company } from '@clout-team/icons/react'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import './ApprovedDomains.scss'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import { useLoginActions } from '../../state/slices/login'
import { useTranslation } from 'react-i18next'
import { isCorpEmail, isInnerIframe } from '../../shared/lib/functions'
import {
  inviteAdmins,
  skipInviteUsers,
  inviteUsers,
  approvedDomains,
} from '../../shared/requests'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useMemo } from 'react'
import { Cookies } from 'react-cookie'
import { referrerRedirect } from '../../shared/lib/urls'
import { REFERRERS } from '../../shared/const/constants'

interface ApprovedDomainsProps {}

const cookies = new Cookies()

const ApprovedDomains = (props: ApprovedDomainsProps) => {
  const {} = props
  const { t } = useTranslation()

  const loginActions = useLoginActions()
  const dispatch = useAppDispatch()

  const hashReg = useAppSelector((state) => state.login.hash_registration)
  const isInviteByEmail = useAppSelector((state) => state.login.isInviteByEmail)
  const companyName = useAppSelector((state) => state.login.company_name)
  const corpDomains = useAppSelector((state) => state.login.corpDomains)
  const currentAccountMail = useAppSelector((state) => state.login.mail ?? '')

  const isCurrentAccountMailCorp = isCorpEmail(currentAccountMail)

  const onSkip = async () => {
    loginActions.setIsMyDomainCorp(false)
    loginActions.setIsDomainInvite(false)

    await dispatch(inviteAdmins({ skip: true, hash_registration: hashReg }))

    if (isInviteByEmail) {
      await dispatch(approvedDomains(hashReg))
    } else {
      await dispatch(skipInviteUsers({ hash_registration: hashReg }))
    }
  }

  const onApprove = async () => {
    if (isCurrentAccountMailCorp) {
      loginActions.setIsMyDomainCorp(true)
    }

    if (corpDomains.length > 0) {
      loginActions.setIsDomainInvite(true)
    }

    await dispatch(inviteAdmins({ skip: true, hash_registration: hashReg }))

    if (isInviteByEmail) {
      dispatch(approvedDomains(hashReg))
    } else {
      await dispatch(skipInviteUsers({ hash_registration: hashReg }))
    }
  }

  const corpDomainsString = useMemo(() => {
    const corpString = []
    if (isCurrentAccountMailCorp) corpString.push(currentAccountMail)
    corpString.push(...corpDomains)
    return corpString.join(', ')
  }, [corpDomains, currentAccountMail, isCurrentAccountMailCorp])

  if (config.IS_MOBILE) {
    const isHasBackButton = isInnerIframe() && cookies.get('token')

    const onBack = () => {
      loginActions.setStep('WORKSPACE')
    }

    return (
      <div className='ap_domains'>
        <MobileHeader
          onClose={isHasBackButton ? onBack : undefined}
          title={t('approved-ds.title')}
          subtitle={
            <Layout isAlignCenter className='invite-company__dialog-subtitle'>
              <Company width={16} height={16} color='var(--ui-text-80)' />
              {companyName}
            </Layout>
          }
        />

        <Layout className='ap_domains_body' vertical gap='lg'>
          <Layout className='ap_domains_info' vertical gap={'md'}>
            <Layout className='ap_domains_info_top' vertical isAlignCenter>
              <Text className={'ap_domains_info_icon'}>📫</Text>
              <Text className={'ap_domains_info_text'} align={'center'}>
                {t('approved-ds.label')}
              </Text>
            </Layout>
            <Text className='ap_domains_info_bottom'>
              {t('approved-ds.description', { value: corpDomainsString })}
            </Text>
          </Layout>

          <ButtonUI size={'xlg'} onClick={onApprove}>
            {t('approved-ds.approve')}
          </ButtonUI>
        </Layout>

        <Layout className={'ap_domains_footer'}>
          <ButtonUI fullWidth size={'xlg'} color={'white'} onClick={onSkip}>
            {t('auth.new.friends.skip')}
          </ButtonUI>
        </Layout>
      </div>
    )
  }

  return null
}

export default ApprovedDomains
