import { ReactComponent as ruIcon } from './icons/ru.svg'
import { ReactComponent as usIcon } from './icons/us.svg'
import { ReactComponent as ukIcon } from './icons/es.svg'
import { ReactComponent as itIcon } from './icons/it.svg'
import { ReactComponent as cnIcon } from './icons/cn.svg'
import { ReactComponent as deIcon } from './icons/de.svg'
import { ReactComponent as frIcon } from './icons/fr.svg'
import { ReactComponent as hiIcon } from './icons/hi.svg'
import { ReactComponent as jaIcon } from './icons/ja.svg'
import { ReactComponent as spIcon } from './icons/sp.svg'
import { ReactComponent as arIcon } from './icons/ar.svg'
import { ReactComponent as ptIcon } from './icons/pt.svg'
import { ReactComponent as koIcon } from './icons/ko.svg'

import './style.scss'

const importList: any = {
  en: usIcon,
  'en-GB': ukIcon,
  de: deIcon,
  es: spIcon,
  esLa: spIcon,
  fr: frIcon,
  it: itIcon,
  pt: ptIcon,
  ru: ruIcon,
  ar: arIcon,
  ja: jaIcon,
  cnSmpl: cnIcon,
  cn: cnIcon,
  ko: koIcon,
  hi: hiIcon
}

export const getCountryImage: any = (reduction: string) => {
  return importList[reduction] ? [importList[reduction]].map((Icon, i) => <Icon key={reduction + '_' + i}/>)[0] : null
}
