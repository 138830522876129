import {Button, config, InviteUsersInCompany, Layout, Text, useCreateLinkInvite,} from '@clout-team/web-components'
import React, {useEffect, useState} from 'react'
import "./InviteUsers.scss"
import InviteUsersMob from './InviteUsersMob'
import { InviteUsersWeb } from './InviteUsersWeb'

const InviteUsers: React.FC = () => {
    return config.IS_MOBILE ? <InviteUsersMob /> : <InviteUsersWeb />
}

export default InviteUsers
