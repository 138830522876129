import React, { useCallback, useEffect, useState } from 'react'
import Domain from '../Domain/Domain'
import Header from '../Header/Header'
import Loading from '../Loading/Loading'
import CodeStep from '../CodeStep/CodeStep'
import Workspace from '../Workspace/Workspace'
import './Main.scss'
import Finish from '../Finish/Finish'
import Profile from '../Profile/Profile'
import InviteUsers from '../InviteUsers'
import JoinCompany from '../JoinCompany/JoinCompany'
import InviteInvalid from '../InviteInvalid/InviteInvalid'
import { Navigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { LoginSteps, useLoginActions } from '../../state/slices/login'
import CompanyBanner from '../CompanyBanner/CompanyBanner'
import AddFriends from '../AddFriends/AddFriends'
import JoinDomain from '../JoinDomain/JoinDomain'
import { BRAND_NAME, UI_TYPE } from '../../shared/const/constants'
import classNames from 'classnames'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { config } from '@clout-team/web-components'
import Promo from '../Promo/Promo'
import { useTranslation } from 'react-i18next'
import { LANG_ARR } from '../../shared/const/lang'
import ApprovedDomains from '../ApprovedDomains/ApprovedDomains'
import { Login } from '../Login'
import { useInitApp } from './useInitApp'
import { isInnerIframe } from '../../shared/lib/functions'
import { ErrorPage } from '../ErrorPage/ErrorPage'

declare global {
  interface Window {
    theme?: string
    appAddAccount?: boolean // если новый аккаунт из электрона
    appAddMobile?: boolean // если новый аккаунт из мобильных приложений
  }
}

const Main: React.FC = () => {
  const { t } = useTranslation()

  const state = useAppSelector((state) => state.login.step)
  const os = useAppSelector((state) => state.ui.os)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { path, locale } = useParams()

  useEffect(() => {
    document.title = `${BRAND_NAME} - ${t('auth.title.authorization')}`
  }, [t])

  useInitApp({
    setIsFullScreen,
    path,
  })

  if (!locale || LANG_ARR.indexOf(locale) === -1) {
    return <InviteInvalid />
  }

  const currentState = (sliceState: LoginSteps) => {
    switch (sliceState) {
      case 'START':
        return <Login />
      case 'CODE':
        return <CodeStep />
      case 'WORKSPACE':
        return <Workspace />
      case 'PROFILE':
        return <Profile />
      case 'FINISH':
        return <Finish />
      case 'DOMAIN':
        return <Domain />
      case 'LOADING':
        return <Loading />
      case 'INVITE_USERS':
        return <InviteUsers />
      case 'JOIN_COMPANY':
        return <JoinCompany />
      case 'INVITE_INVALID':
        return <InviteInvalid />
      case 'COMPANY_BANNER':
        return <CompanyBanner />
      case 'ADD_FRIENDS':
        return <AddFriends />
      case 'JOIN_DOMAIN':
        return <JoinDomain />
      case 'ERROR':
        return <ErrorPage />
      case 'PROMO':
        return <Promo />
      case 'APPROVE_DOMAINS':
        return <ApprovedDomains />
    }
  }

  const showHeader =
    state !== 'INVITE_INVALID' && state !== 'COMPANY_BANNER' && !config.IS_MOBILE

  return (
    <div
      className={classNames('p_main', {
        'is-electron-auth': isElectron(),
        'is-full-screen': isFullScreen,
        'is-electron-auth-mac': isElectron() && os === UI_TYPE.MAC,
        'is-electron-auth-win': isElectron() && os === UI_TYPE.WIN,
        'is-padding-off': config.IS_MOBILE,
      })}
    >
      {showHeader && <Header />}
      <>{currentState(state)}</>
    </div>
  )
}

export default Main
