import { config } from '@clout-team/web-components'
import React from 'react'
import './CodeStep.scss'
import CodeStepMobile from './CodeStepMobile'
import CodeStepWeb from './CodeStepWeb'

const CodeStep: React.FC = () => {
  return config.IS_MOBILE ? <CodeStepMobile /> : <CodeStepWeb />
}

export default CodeStep
