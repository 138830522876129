import React from 'react'
import { Dialog } from '@clout-team/web-components'

const DialogPrivacy = (props: {
  L: (s: string) => void,
  onClose: () => void
}) => {
  const { L, onClose } = props

  return (
    <Dialog
      className={'dialog-auth-privacy'}
      closeOnClickOutside={false}
      dialogTitle={{
        title: L('auth.privacy.dialog.title'),
        varOnlyTitle: false,
      }}
      onClose={onClose}
      closeOnEsc
      size={'lg'}
      isScrollContent
    >
      <strong><p style={{ margin: 'margin: 20px 0px 20px' }}>УСЛОВИЯ ОБСЛУЖИВАНИЯ LENZA OS</p></strong><p>ВАЖНО.
      ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ: ИСПОЛЬЗОВАНИЕ И ДОСТУП К ВЕБ-САЙТУ, ПРОДУКТАМ, УСЛУГАМ И СВЯЗАННОМУ ПРОГРАММНОМУ
      ОБЕСПЕЧЕНИЮ (ДАЛЕЕ СОВМЕСТНО — «УСЛУГИ») КОМПАНИИ MAILCHAT INC. И ЕЕ АФФИЛИРОВАННЫХ СТОРОН (ДАЛЕЕ — LENZA OS)
      ОСУЩЕСТВЛЯЮТСЯ ПРИ УСЛОВИИ ПРИНЯТИЯ И СОБЛЮДЕНИЯ ВАМИ НАСТОЯЩИХ УСЛОВИЙ, КОТОРЫЕ ВКЛЮЧАЮТ ВАШЕ СОГЛАСИЕ НА
      РАССМОТРЕНИЕ СПОРОВ В АРБИТРАЖНОМ СУДЕ. ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ С НАСТОЯЩИМ ДОКУМЕНТОМ, ПРЕЖДЕ ЧЕМ ПРИНЯТЬ ЕГО
      ПОЛОЖЕНИЯ.</p><p>НАЖАВ КНОПКУ ИЛИ УСТАНОВИВ ФЛАЖОК «Я СОГЛАСЕН», ОСУЩЕСТВЛЯЯ ДОСТУП К ВЕБ-САЙТУ LENZA OS ИЛИ
      ИСПОЛЬЗУЯ УСЛУГИ LENZA OS, ВЫ СОГЛАШАЕТЕСЬ СОБЛЮДАТЬ НАСТОЯЩИЕ УСЛОВИЯ ОБСЛУЖИВАНИЯ И ПРЕДПИСАНИЯ ВСЕХ ПРИЛОЖЕНИЙ,
      ФОРМ ЗАКАЗА И ВКЛЮЧЕННЫХ ПОЛИТИК (ДАЛЕЕ — «СОГЛАШЕНИЕ» ИЛИ «УСЛОВИЯ ОБСЛУЖИВАНИЯ»). УСЛУГИ LENZA OS НЕДОСТУПНЫ ДЛЯ
      ЛИЦ, КОТОРЫЕ НЕ ИМЕЮТ ПРАВА В СИЛУ ЗАКОНОДАТЕЛЬСТВА ВЗЯТЬ НА СЕБЯ ОБЯЗАТЕЛЬСТВА ПО СОБЛЮДЕНИЮ УСЛОВИЙ
      ОБСЛУЖИВАНИЯ.</p><p>Компания Mailchat Inc. предоставляет Услуги, и вы можете получать доступ к Услугам и
      использовать их в соответствии с настоящим Соглашением. Компания Mailchat Inc. может предоставлять любые из
      перечисленных ниже Услуг через любую из своих Аффилированных сторон. Если вы заказываете Услуги на странице
      интернет-регистрации или с использованием формы заказа (далее&nbsp;— «Форма заказа»), Форма заказа может содержать
      дополнительные условия и положения, а также информацию в отношении заказываемых вами Услуг. Если иное прямо не
      указано в таких дополнительных условиях и положениях, применимых к конкретной Услуге, выбранной вами для
      использования, эти дополнительные условия включаются в настоящее Соглашение в связи с вашим использованием этой
      Услуги.</p><p>Системные требования.&nbsp;Для использования Услуг требуется одно или несколько совместимых
      устройств, доступ в интернет (может взиматься плата) и определенное программное обеспечение (может взиматься
      плата), а также может потребоваться периодическое получение обновлений. Поскольку использование Услуг
      предусматривает применение аппаратных средств, программного обеспечения и доступа в интернет, ваши возможности
      доступа к таким Услугам и их использования могут зависеть от влияния этих факторов. Рекомендуется использовать
      высокоскоростной доступ в интернет. Вы признаете и соглашаетесь с тем, что такие системные требования, которые
      могут периодически изменяться, относятся к вашей сфере ответственности.</p><p>ОПРЕДЕЛЕНИЯ.&nbsp;К настоящему
      Соглашению применяются следующие определения, причем все упоминания в единственном числе соответствуют упоминаниям
      во множественном и наоборот. Определения, связанные с Услугами, приведены в «Описании услуг Lenza OS».</p>
      <p>«Аффилированный» (в отношении стороны)&nbsp;— юридическое лицо, которое прямо или косвенно управляет или
        является такой стороной или находится вместе с ней под единым управлением. В рамках настоящего Соглашения
        «управление» означает экономическую долю или долю голосов в размере не менее 50% (пятидесяти процентов) либо при
        отсутствии таких долей способность направлять или задавать направление руководства и устанавливать политики для
        такого юридического лица.</p><p>«Конечный пользователь»&nbsp;— организатор или участник (как определено
      в&nbsp;«Описании услуг Lenza OS»), использующий Услуги.</p><p>«Срок первоначальной подписки»&nbsp;— срок
      первоначальной подписки на Услугу, как указано в Форме заказа.</p><p>«Дата начала работы с услугой»&nbsp;— дата
      начала Срока первоначальной подписки, как указано в Форме заказа.</p><p>«Срок продления»&nbsp;— срок продления
      подписки на Услугу, отсчет которого начинается по истечении Срока первоначальной подписки или другого Срока
      продления, как указано в Форме заказа.</p><p>«Налоги и сборы» и «Налоги или сборы» — все применимые налоги с
      продаж, налоги на использование, экологические или регулятивные налоги, НДС, сборы, пошлины (включая таможенные
      пошлины), комиссии, надбавки или отчисления, взимаемые при предоставлении Услуг Клиенту (не включая возможный
      подоходный налог, взимаемый с компании Mailchat Inc.).</p><p>«НДС» — любой налог на добавленную стоимость и любой
      иной налог аналогичного характера, взимаемый в стране Европейского союза или в какой-либо иной стране вместо
      налога на добавленную стоимость или в дополнение к нему, любой налог на товары и услуги, взнос по программе
      социальной интеграции (PIS) / взнос на финансирование социального обеспечения (COFINS), любой аналогичный
      косвенный налог или любой аналогичный им налог, взимаемый в связи с Услугами, предоставляемыми компанией Mailchat
      Inc. Клиенту, или иным образом относящийся к Услугам, предоставляемым компанией Mailchat Inc. клиенту.</p><p>«Ваши
      данные» — информация, предоставляемая компании Mailchat Inc. в целях выполнения условий Соглашения и
      предоставления доступа к Услугам (например, название компании, платежный адрес, идентификационный номер
      налогоплательщика, регистрационный номер плательщика НДС, имя контактного лица и контактная информация). Вы несете
      единоличную ответственность за точность Ваших данных, при этом Mailchat Inc. не несет какой-либо ответственности
      за ошибки и упущения в Ваших данных.</p><p>1. УСЛУГИ.&nbsp;Компания Mailchat Inc. предоставляет сервисы, как
      указано в форме заказа, и их стандартные обновления, которые Компания Mailchat Inc. делает общедоступными в
      течение срока действия соглашения. Компания Mailchat Inc. может по своему исключительному усмотрению прекратить
      предоставление Услуг или периодически изменять функции Услуг без предварительного уведомления.</p><p>a.
      БЕТА-ВЕРСИИ УСЛУГ.&nbsp;Компания Mailchat Inc. может периодически предлагать доступ к бета-версиям сервисов.
      Доступ к бета-версиям и их использование могут регламентироваться дополнительными соглашениями. Компания Mailchat
      Inc. не дает никаких заверений насчет доступности бета-версий для широкой публики и оставляет за собой право
      прекратить поддержку или вносить изменения в бета-версию в любой момент без предварительного уведомления.
      Бета-версии предоставляются КАК ЕСТЬ, могут содержать ошибки и прочие дефекты. Использование бета-версии
      осуществляется исключительно на ваш риск.</p><p>2. ИСПОЛЬЗОВАНИЕ УСЛУГ И ВАШИ ОБЯЗАННОСТИ.&nbsp;Вы вправе
      использовать Услуги исключительно в соответствии с условиями настоящего Соглашения. Вы несете полную
      ответственность за использование Услуг вами и вашими Конечными пользователями и обязуетесь соблюдать и
      обеспечивать соответствие всем применимым законам, связанным с использованием Услуг вами и Конечными
      пользователями, включая, помимо прочего, законы, связанные с записью, интеллектуальной собственностью,
      конфиденциальностью и экспортным контролем. Использование Услуг при наложении запрета прекращается.</p><p>a.
      Регистрационные данные.&nbsp;От вас может потребоваться предоставить сведения о себе для регистрации и/или
      использования определенных сервисов. Вы соглашаетесь с тем, что такие данные должны быть точными. Вас также могут
      попросить выбрать имя пользователя и пароль. Вы несете полную ответственность за обеспечение безопасности вашего
      имени пользователя и пароля и соглашаетесь не раскрывать их третьим сторонам.</p><p>b. Содержимое.&nbsp;Вы
      соглашаетесь с тем, что вы несете исключительную ответственность за содержимое (далее&nbsp;— «содержимое»),
      передаваемое, отображаемое или выгружаемое вами в процессе использования сервисов, а также за обеспечение
      соответствия требованиям нормативных актов, относящихся к содержимому, в том числе законов, требующих получения
      согласия на использование содержимого у третьих сторон и предоставления соответствующих уведомлений о правах
      третьих сторон. Вы заверяете и гарантируете, что у вас есть право на выгрузку Содержимого в Lenza OS и что такое
      использование не нарушает права третьих сторон. Ни при каких обстоятельствах Компания Mailchat Inc. не несет
      ответственности за (а) Содержимое, передаваемое или просматриваемое при использовании Услуг, (б) ошибки или
      упущения в Содержимом либо (в) любые убытки или ущерб любого рода, понесенный в результате использования, доступа
      или запрета доступа к Содержимому. Несмотря на то что Компания Mailchat Inc. не несет ответственности за
      Содержимое, Компания Mailchat Inc. оставляет за собой право удалить любое Содержимое в любой момент времени и без
      предварительного уведомления, если компании Mailchat Inc. станет известно, что оно нарушает какое-либо из
      положений настоящего Соглашения или какой-либо закон. Вы сохраняете авторское право и любые иные права, которыми
      вы обладаете в отношении Содержимого, отправленного, размещенного или отображаемого посредством Услуг.</p><p>c.
      Записи.&nbsp;Вы несете ответственность за соблюдение всех законов, относящихся к записям. Организатор может
      принять решение о записи конференций Lenza OS Meetings. Используя Услуги, вы предоставляете Lenza OS согласие на
      хранение записей любых конференций Lenza OS Meetings, к которым вы присоединяетесь, если такие записи хранятся в
      наших системах. Если функция записи включена, вам будет направлено уведомление (визуальное или иное). Если вы не
      согласны с тем, что ведется запись, вы можете покинуть конференцию или вебинар.</p><p>d. Запрещенное
      использование.&nbsp;Вы соглашаетесь с тем, что не будете осуществлять следующие действия сами и не будете
      позволять осуществлять их Конечным пользователям: (i) модифицировать, разбирать, декомпилировать, создавать
      производные работы, переконструировать или иным образом пытаться получить доступ к исходному коду Услуг; (ii)
      сознательно или халатно использовать Услуги способом, выражающимся в ненадлежащем использовании, вмешательстве или
      нарушении работы сетей Lenza OS, Ваших учетных записей или Услуг; (iii) участвовать в незаконной, мошеннической,
      обманной и вводящей в заблуждение деятельности; (iv) передавать с помощью Услуг любые материалы, нарушающие права
      на интеллектуальную собственность или иные права третьих сторон; (v) выполнять разработку или сравнительное
      тестирование конкурирующих продуктов или услуг или копировать возможности, функции или графические элементы Услуг;
      (vi) использовать Услуги для обмена любыми сообщениями или материалами, которые являются унизительными,
      клеветническими, угрожающими, оскорбительными, непристойными, нарушают права на интеллектуальную собственность
      любой стороны или носят иной противозаконный характер, который может повлечь за собой гражданскую ответственность,
      или которые представляют или поощряют действия, которые могут считаться уголовным преступлением в соответствии с
      любыми действующим законами и нормативными актами; (vii) выгружать или передавать любое программное обеспечение,
      Содержимое или код, который причиняет вред или предназначен для причинения вреда, отключения, уничтожения или
      неблагоприятного воздействия на предоставление Услуг любым образом либо который причиняет вред или предназначен
      для причинения вреда или извлечения информации или данных из других аппаратных средств, программного обеспечения
      или сетей компании Mailchat Inc. либо иных пользователей Услуг; (viii) участвовать в любой деятельности или
      использовать Услуги любым образом, который может повлечь за собой ущерб, ограничение, избыточную нагрузку,
      нарушение или иное вмешательство или препятствие предоставлению Услуг или работе любых серверов или сетей,
      связанных с Услугами или системами безопасности компании Mailchat Inc.. (ix) использовать Услуги в нарушение любой
      политики компании Mailchat Inc. или таким образом, который нарушает действующее законодательство, включая, помимо
      прочего, законы и нормативные акты в отношении борьбы со спамом, экспортного контроля, конфиденциальности и борьбы
      с терроризмом, а также законы, требующие согласия со стороны участников аудио- и видеозаписей; и с тем, что вы
      несете исключительную ответственность за соблюдение положений любых таких законов и нормативных актов.</p><p>e.
      Ограничения на использование.&nbsp;Вам запрещается воспроизведение, перепродажа или распространение Услуг либо
      любых отчетов или данных, сгенерированных Услугами для любых целей, если вы не получили конкретного разрешения на
      осуществление указанных действий в рамках отдельного соглашения с компанией Mailchat Inc. Вам запрещается
      предлагать или предоставлять третьим лицам доступ к использованию приобретенных вами Услуг, демонстрировать на
      любом веб-сайте или иным образом публиковать Услуги или любое Содержимое, полученное в связи с использованием
      Услуги (за исключением созданного вами Содержимого), или иным образом получать доход от Услуг либо использовать
      Услуги для разработки, производства или маркетинга услуг или продуктов, по существу аналогичных Услугам.</p><p>3.
      ОТВЕТСТВЕННОСТЬ ДЛЯ КОНЕЧНЫХ ПОЛЬЗОВАТЕЛЕЙ.&nbsp;Вы несете ответственность за действия всех Конечных
      пользователей, получающих доступ к Услугам через вашу учетную запись, и за соблюдение такими Конечными
      пользователями положений настоящего Соглашения и всех политик компании Mailchat Inc. Компания Mailchat Inc. не
      несет ответственности и обязательств из-за нарушений. Если вы стали свидетелем любого нарушения настоящего
      Соглашения в связи с использованием Услуг любым лицом, обратитесь в компанию Mailchat Inc. по
      адресу&nbsp;trust@lenzaos.com. Компания Mailchat Inc. может расследовать любые жалобы и нарушения, доведенные до
      ее внимания, и может предпринимать (или не предпринимать) любые действия, которые будут сочтены необходимыми,
      включая, помимо прочего, направление предупреждений, удаление Содержимого или удаление учетных записей и/или
      профилей пользователей. Ни при каких обстоятельствах Компания Mailchat Inc. не несет какой-либо ответственности за
      любые данные или другое Содержимое, просматриваемое при использовании Услуг, включая, помимо прочего, любые ошибки
      или упущения в любых таких данных или Содержимом, или любые потери или ущерб любого рода, понесенный в результате
      использования, доступа или запрета доступа к любым данным или Содержимому.</p><p>4. ОБЯЗАТЕЛЬСТВА КОМПАНИИ
      MAILCHAT INC. В ОТНОШЕНИИ СОДЕРЖИМОГО.&nbsp;Компания Mailchat Inc. обязуется в соответствии с отраслевыми
      стандартами использовать разумно необходимые физические и технические средства защиты для недопущения
      несанкционированного доступа к Содержимому или его раскрытия. Компания Mailchat Inc. уведомит вас, если ей станет
      известно о несанкционированном доступе к Содержимому. Компания Mailchat Inc. будет получать доступ к Содержимому,
      а также осуществлять его просмотр или обработку исключительно на следующих условиях: (а) в соответствии с
      положениями настоящего Соглашения и Заявления о конфиденциальности Lenza OS; (б) при наличии разрешения или
      инструкций с вашей стороны; (в) в соответствии с требованиями, связанными с исполнением обязательств в связи с
      настоящим Соглашением; (г) в соответствии с требованиями законодательства. У компании Mailchat Inc. нет иных
      обязательств в отношении Содержимого.</p><p>5. ПРАВОМЕРНОСТЬ.&nbsp;Вы подтверждаете, что вам не менее 16&nbsp;лет
      и в остальном вы обладаете полной дееспособностью и правоспособностью для принятия условий, положений,
      обязательств, подтверждений, заверений и гарантий, установленных в настоящем Соглашении, а также для выполнения и
      соблюдения настоящего Соглашения. Если у нас возникнут подозрения, что вы не достигли возраста 16&nbsp;лет или
      иным образом не имеете права на использование Услуги, доступ может быть прекращен без предупреждения.</p><p>6.
      ПРЕДПОЛАГАЕМОЕ ИСПОЛЬЗОВАНИЕ; ОГРАНИЧЕНИЕ НА ИСПОЛЬЗОВАНИЕ ДЕТЬМИ.&nbsp;Услуги предназначены для удовлетворения
      бизнес-потребностей. Вы можете использовать Услуги для достижения иных целей в соответствии с условиями и
      ограничениями настоящего Соглашения. Услуги Lenza OS не предназначены для использования лицами моложе 16 лет, за
      исключением случаев, когда такое использование осуществляется через Подписчика в сфере образования (определение
      термина см. в Описании услуг), использующего Lenza OS для Образовательных учреждений (школ).</p><p>7. ОПЛАТА И
      ОТМЕНА.&nbsp;Вы соглашаетесь, что Компания Mailchat Inc. может взимать плату за Услуги с вашей кредитной карты или
      использовать другой механизм оплаты, выбранный Вами и утвержденный компанией Mailchat Inc. («Ваша учетная
      запись»). Все платежи, которые вы осуществляете нам в соответствии с настоящим соглашением, должны производиться
      без каких-либо вычетов или удержаний, которые могут быть предусмотрены законодательством. Если для какого-либо
      платежа предусмотрен такой вычет или удержание (включая, помимо прочего, внутренние или трансграничные налоги), вы
      соглашаетесь заплатить такие требуемые дополнительные суммы, чтобы полученная нами чистая сумма была равна сумме,
      причитающейся к оплате по настоящему соглашению. Мы предоставим вам обоснованно запрашиваемые налоговые формы для
      уменьшения или отмены суммы любого удержания или вычета налогов в отношении платежей, осуществляемых по настоящему
      соглашению. Компания Mailchat Inc. может изменять цены в любой момент, включая переход от бесплатного
      предоставления Услуг к платному и взимание платы за Услуги, которые ранее предоставлялись бесплатно; однако при
      условии предоставления компанией Mailchat Inc. предварительного уведомления и возможности удалить Вашу учетную
      запись при изменении компанией Mailchat Inc. стоимости Услуги, на которую вы подписаны, и исключения возможности
      списания платы за ранее бесплатную Услугу, если вы не были уведомлены об установлении платы и не дали согласия на
      оплату. Вы соглашаетесь, что в случае если Компания Mailchat Inc. не в состоянии получить плату, причитающуюся
      компании Mailchat Inc. за использование Услуг, через вашу учетную запись, Компания Mailchat Inc. может предпринять
      любые другие шаги, которые сочтет необходимыми для получения такой платы от вас, и что вы несете ответственность
      за все затраты и расходы, понесенные компанией Mailchat Inc. в связи с такими действиями по взиманию платы,
      включая сборы за доставку, судебные издержки и оплату юридических услуг. Кроме того, вы соглашаетесь, что Компания
      Mailchat Inc. может взимать проценты по ставке до 1,5&nbsp;% в месяц или в большем размере в соответствии с
      требованиями законодательства по любым просроченным суммам. Вы можете отменить свою подписку в любой момент. В
      случае отмены вам не будет выставлен счет за любые дополнительные условия обслуживания, а услуга продлится до
      конца текущего Срока подписки. При отмене вам не будет предоставлено возмещение за уже оплаченные услуги.</p><p>8.
      НАЛОГИ.&nbsp;Если не указано иное, все цены и сборы, указанные компанией Mailchat Inc., не включают в себя Налоги
      и другие сборы, предусмотренные законодательством, плату за услуги, сервисные сборы, плату за настройку,
      абонентскую плату или любую иную плату, связанную с Вашей учетной записью. Там, где это применимо, Налоги и другие
      сборы, предусмотренные законодательством, будут взиматься на основании счетов-фактур, выставляемых компанией
      Mailchat Inc., в соответствии с местными законами и нормативно-правовыми актами. Компания Mailchat Inc. будет по
      своему усмотрению рассчитывать сумму подлежащих уплате Налогов. Налоги и другие сборы, предусмотренные
      законодательством, могут быть изменены без предварительного уведомления.</p><p>a. Счета-фактуры (т.&nbsp;е. счета
      с НДС).&nbsp;Если это требуется по закону, Компания Mailchat Inc. выставит вам счет-фактуру или документ, который
      соответствующий налоговый орган будет рассматривать как счет-фактуру. Вы соглашаетесь с тем, что такой
      счет-фактура может быть выставлен в электронной форме.</p><p>b. Освобождение от налогообложения.&nbsp;Если вы
      освобождены от уплаты каких-либо налогов или сборов, вы должны будете предоставить компании Mailchat Inc. все
      соответствующие справки об освобождении от уплаты налогов и/или иную документацию, удовлетворяющую причастные
      налоговые органы, в целях обоснования такого освобождения. Компания Mailchat Inc. оставляет за собой право
      проверять и подтверждать документы об освобождении от налогообложения. Если документация об освобождении от
      налогообложения является неприемлемой, Компания Mailchat Inc. оставляет за собой право относить применимые налоги
      на ваш счет.</p><p>c. Уплата Налогов и сборов.&nbsp;Вы будете платить компании Mailchat Inc. любые применимые
      Налоги и сборы. Вы несете единоличную ответственность за уплату всех без исключения Налогов и сборов, подлежащих
      уплате в результате предоставления вам Услуг компанией Mailchat Inc.. Если от вас требуется уплатить какие-либо
      Налоги и сборы, вы должны уплатить их без уменьшения или взаимозачета сумм, подлежащих уплате компании Mailchat
      Inc. по настоящему Соглашению, при этом вы будете принимать на свой счет и выплачивать любую дополнительную сумму,
      которая потребуется для того, чтобы Компания Mailchat Inc. получила полную сумму платежа в том объеме, в котором
      она бы получила ее без такого примененного уменьшения или взаимозачета.</p><p>d. НДС, подлежащий уплате
      клиентом.&nbsp;Если Налоги и сборы подлежат уплате налоговым органам вами вместо компании Mailchat Inc.
      посредством обратного начисления или иной подобной процедуры, вы предоставите компании Mailchat Inc. все
      соответствующие доказательства, на основании которых Компания Mailchat Inc. сможет продемонстрировать характер
      вашей хозяйственной деятельности, например, действительный регистрационный номер плательщика НДС (или аналогичную
      информацию, требующуюся по применимому законодательству в области НДС). Mailchat Inc. оставляет за собой право
      проверять и подтверждать ваш регистрационный номер плательщика НДС. Если регистрационный номер плательщика НДС
      недействителен, Компания Mailchat Inc. оставляет за собой право взимать с вас применимый НДС. Во избежание
      неоднозначности, если НДС подлежит уплате налоговому органу вами посредством обратного начисления или иной
      подобной процедуры, вы несете единоличную ответственность за уплату таких сумм соответствующему налоговому органу
      таким образом, чтобы Компания Mailchat Inc. получила полную требуемую сумму платежа.</p><p>e. Определение
      налога.&nbsp;Налог определяется прежде всего тем, где, согласно Данным клиента, учреждена организация Клиента, а в
      случае с физическими лицами — местом постоянного проживания физического лица. Компания Mailchat Inc. будет
      опираться на ваш адрес плательщика. Компания Mailchat Inc. оставляет за собой право сопоставлять такое
      местоположение с другими доступными фактическими данными, чтобы убедиться в его точности. Если ваше местоположение
      указано неточно, Компания Mailchat Inc. оставляет за собой право относить на ваш счет любые неуплаченные Налоги и
      сборы.</p><p>f. Пользование и распоряжение.&nbsp;Если вы приобретаете Услуги Lenza OS, а Ваша дочерняя организация
      пользуется и распоряжается ими в стране, которая отличается от Вашего местоположения согласно определению этого
      термина в Разделе&nbsp;9(e) настоящих Условий обслуживания, вы подтверждаете, что при необходимости будете считать
      это поставкой в Вашу дочернюю организацию. Если вы приобретаете Услуги, а филиал или физическое лицо пользуется и
      распоряжается ими в стране, которая отличается от Вашего местоположения согласно определению этого термина в
      Разделе&nbsp;9(e) настоящих Условий обслуживания, вы подтверждаете, что уведомите Lenza OS об Услугах, которые
      были выделены, а также признаёте, что Компания Mailchat Inc. оставляет за собой право взимать Налоги и сборы,
      исходя из пользования и распоряжения такими Услугами.</p><p>9. РАСТОРЖЕНИЕ.&nbsp;На веб-сайте Lenza OS содержатся
      инструкции по удалению Вашей учетной записи. Если вы приобрели Услугу на определенный срок, такое расторжение
      Соглашения вступит в силу в последний день соответствующего срока. Ваша Форма заказа может предусматривать
      автоматическое начало Срока продления, если одной из сторон не направлено уведомление о расторжении по крайней
      мере за 30 (тридцать) дней до начала следующего Срока продления. При несоблюдении вами любого положения настоящего
      Соглашения Компания Mailchat Inc. может немедленно расторгнуть настоящее Соглашение без возврата предварительно
      уплаченных вами средств. Разделы 1 и с 3 по 22 включительно сохраняют действие при расторжении настоящего
      Соглашения, независимо от причины расторжения. При расторжении настоящего Соглашения по любой причине вы должны
      прекратить любое дальнейшее использование Услуг. Если вас не устроит качество Услуг в какой-либо момент времени,
      ваше исключительное средство правовой защиты заключается в прекращении использования Услуг и исполнении настоящего
      процесса расторжения Соглашения и удаления учетной записи.</p><p>10. ПРАВА СОБСТВЕННОСТИ.&nbsp;Компания Mailchat
      Inc. и/или ее поставщики в соответствующих случаях обладают всеми правами собственности на Услуги и все торговые
      названия, товарные знаки, знаки обслуживания, логотипы и имена доменов (далее&nbsp;— «Знаки Lenza OS»), связанные
      с Услугами или отображаемые в них. Вы не вправе захватывать кадры или использовать технологии захвата кадров для
      того, чтобы включить любые Знаки Lenza OS или иную информацию, являющуюся собственностью компании Mailchat Inc. (в
      том числе изображения, текст, макеты страниц или формы), без явно выраженного письменного согласия. Вы не можете
      использовать любые метатеги или любой иной «скрытый текст», в котором используются Знаки Lenza OS, без явно
      выраженного письменного согласия компании Mailchat Inc.</p><p>11. КОНФИДЕНЦИАЛЬНОСТЬ. Каждая сторона соглашается
      признавать и сохранять конфиденциальность всей непубличной информации, предоставленной в любой форме
      противоположной стороной и касающейся хозяйственной деятельности, систем, операций, стратегических планов,
      клиентов, ценообразования (включая, помимо прочего, условия ценообразования, содержащиеся в настоящем документе),
      методов, процессов, финансовых данных, программ и/или продуктов противоположной стороны, когда такая информация
      обозначена как конфиденциальная или когда любое здравомыслящее лицо знает или должно понимать, что такая
      информация является конфиденциальной (далее — «Конфиденциальная информация»). Для целей настоящего Соглашения ваша
      Конфиденциальная информация должна включать в себя Ваши данные и любую информацию, предоставленную вами компании
      Mailchat Inc. касательно вашего бизнеса, систем, операций, стратегических планов, клиентов, ценообразования,
      методов, процессов, финансовых данных, программ и/или продуктов. Каждая сторона соглашается раскрывать
      Конфиденциальную информацию противоположной стороны как можно меньшему количеству лиц и ограничивать это
      количество лицами, которым необходимо знать такую информацию, то есть своими сотрудниками и сотрудниками своих
      Аффилированных сторон, которые обязаны сохранять конфиденциальность такой информации. За исключением случаев,
      когда это необходимо для исполнения соответствующих обязательств по Соглашению, ни одна из сторон не должна без
      получения предварительного письменного согласия противоположной стороны раскрывать информацию какому-либо
      физическому лицу, коммерческой организации или предприятию, если это не было прямо разрешено в настоящем
      документе, или использовать в своих интересах или в интересах третьей стороны Конфиденциальную информацию
      противоположной стороны.</p><p>a. Исключения. В понятие «Конфиденциальная информация» не входят Содержимое или
      информация, которая (а) уже правомерно известна одной из сторон на момент ее получения от противоположной стороны
      без каких-либо обязательств по сохранению конфиденциальности такой информации; (б) является или становится
      общеизвестной или общедоступной без противоправных действий какой-либо из сторон; (в) правомерно получена от
      третьей стороны без ограничений и без нарушения настоящих Условий обслуживания; или (г) произведена одной из
      стороной без использования какой-либо частной непубличной информации, предоставленной противоположной стороной по
      Соглашению.</p><p>b. Исключение. Любая из сторон может раскрыть Конфиденциальную информацию, если это требуется
      законом, нормативно-правовыми актами или постановлением суда, при этом сторона, на которую распространяется
      действие такого закона, нормативно-правового акта или постановления суда, должна, когда это разрешено, уведомить
      противоположную сторону о любом таком использовании или требовании до раскрытия информации, чтобы предоставить
      противоположной стороне возможность запросить приказ, отменяющий требование о раскрытии Конфиденциальной
      информации, в целях предотвращения или ограничения раскрытия информации третьим лицам.</p><p>c. Срок соблюдения
      конфиденциальности и обязательства по соблюдению конфиденциальности. Обязательства по соблюдению
      конфиденциальности, изложенные в настоящем разделе Условий обслуживания, остаются в силе в течение 5 (пяти) лет с
      момента раскрытия информации. Обе стороны соглашаются (а) предпринимать разумные меры в целях защиты
      Конфиденциальной информации противоположной стороны, при этом такие меры должны быть не менее оградительными, чем
      меры, предпринимаемые противоположной стороной для защиты своей собственной Конфиденциальной информации, и как
      минимум соответствовать должному уровню осмотрительности; (б) незамедлительно уведомлять сторону, раскрывающую
      информацию, при обнаружении любого несанкционированного использования или раскрытия Конфиденциальной информации; а
      также (в) в случае любого несанкционированного раскрытия информации стороной, принимающей раскрываемую информацию,
      сотрудничать со стороной, раскрывающей информацию, в целях восстановления контроля над Конфиденциальной
      информацией и предотвращения ее дальнейшего несанкционированного использования или раскрытия.</p><p>АВТОРСКОЕ
      ПРАВО.&nbsp;Вы не имеете права публиковать, изменять, распространять или воспроизводить каким-либо образом
      защищенные авторским правом материалы, товарные знаки, права на публичное использование или иные права
      собственности без предварительного письменного согласия со стороны владельца таких прав собственности. Компания
      Mailchat Inc. может запретить доступ к Услугам любому Пользователю, который подозревается в нарушении авторских
      прав другой стороны. Не ограничивая смысл вышеизложенного, если вы считаете, что ваше авторское право было
      нарушено, обратитесь в компанию Mailchat Inc, как описано в документе «Удаление в связи с Законом об авторском
      праве в цифровую эпоху (DMCA) и использованием товарных знаков».</p><p>12. ЭКСПОРТНЫЕ ОГРАНИЧЕНИЯ. Вы признаёте,
      что на Услуги в целом или их часть может распространяться действие Правил экспортного контроля, а также частей
      730–774 параграфа 15 Свода федеральных нормативных актов США и иного применимого законодательства других стран в
      области экспортного контроля и торговых санкций (далее — «Законодательство в области экспортного контроля и
      торговых санкций»). Компания Mailchat Inc. по запросу предоставляет для своих Услуг классификацию экспортных
      товаров США. Вы и ваши Конечные пользователи не можете прямо или косвенно осуществлять доступ, эксплуатацию,
      экспорт, реэкспорт, переадресацию, передачу или раскрытие любой части Услуг или любой связанной с ними технической
      информации либо материалов в нарушение положений Законодательства в области экспортного контроля и торговых
      санкций. Вы заверяете и гарантируете следующее: (i) вы вместе с вашими Конечными пользователями (а) не являетесь
      гражданами или резидентами стран или территорий, находящихся под действием торговых санкций со стороны США или
      иных значимых торговых ограничений (включая, помимо прочего, Кубу, Иран, Северную Корею, Сирию, Крым, Донецкую и
      Луганскую области Украины), и вы вместе с вашими Конечными пользователями не будете использовать Услуги, или
      получать к ним доступ, или экспортировать, реэкспортировать, переадресовывать, или передавать Услуги в такие
      страны либо на территории; (б) не являетесь лицами или не принадлежите в индивидуальном порядке или в совокупности
      на 50 или более процентов к лицам, входящим в списки особых категорий лиц, находящихся под санкциями, и
      иностранных лиц, уклоняющихся от выполнения санкций, составленные Министерством финансов США; (в) не находитесь в
      списках запрещенных лиц, организаций и непроверенных партнеров, составленных Министерством торговли США, и
      списках, связанных с распространением оружия массового уничтожения, составленных Госдепартаментом США; (ii) вы и
      ваши Конечные пользователи, расположенные в Китае, России или Венесуэле, не являетесь Военными конечными
      пользователями и не будете использовать Услуги Lenza OS в военных целях, как указано в пункте 21 статьи 744
      параграфа 15 Свода федеральных нормативных актов США; (iii) Содержимое, создаваемое или передаваемое вами и вашими
      Конечными пользователями, не подпадает под ограничения, связанные с раскрытием, передачей, загрузкой, экспортом
      или реэкспортом, согласно требованиям Законодательства в области экспортного контроля и торговых санкций; и (iv)
      вы и ваши Конечные пользователи не будете выполнять какие-либо действия, которые представляли бы собой нарушение
      или предусматривали бы наказание в соответствии с Законами о соблюдении антибойкотных норм США, находящимися в
      ведении Министерства торговли США или Министерства финансов США. Вы несете исключительную ответственность за
      соответствие требованиям Законодательства в области экспортного контроля и торговых санкций и отслеживание
      изменений в их содержании.</p><p>13. НЕДОПУСТИМОСТЬ ИСПОЛЬЗОВАНИЯ В УСЛОВИЯХ С ВЫСОКИМ УРОВНЕМ РИСКА.&nbsp;Услуги
      не предназначены и не лицензированы для использования в опасных условиях, требующих отказоустойчивого управления,
      включая в числе прочего управление объектами использования атомной энергии, системами авиационной навигации или
      коммуникации, авиадиспетчерскими службами, системами жизнеобеспечения или вооружения. Не допускается использовать
      Услуги в любых средах с ВЫСОКИМ УРОВНЕМ РИСКА.</p><p>14. СУДЕБНЫЙ ЗАПРЕТ.&nbsp;Вы признаете, что любое
      использование Услуг в нарушение настоящего соглашения или любая передача, предоставление сублицензии, копирование
      или раскрытие технической информации или материалов, связанных с Услугами, может причинить непоправимый ущерб
      компании Mailchat Inc., ее аффилированным сторонам, поставщикам и любой другой стороне, уполномоченной компанией
      Mailchat Inc. перепродавать, распространять или продвигать Услуги (далее&nbsp;— «Партнеры по продажам»), и при
      таких обстоятельствах Компания Mailchat Inc., ее филиалы, поставщики и Партнеры по продажам имеют право прибегнуть
      к средству судебной защиты в виде запрета без внесения залога или финансового обеспечения, включая, помимо
      прочего, предварительный или постоянный судебный запрет.</p><p>15. ОТКАЗ ОТ ГАРАНТИЙ.&nbsp;ВЫ ОСОЗНАЕТЕ И
      СОГЛАШАЕТЕСЬ С ТЕМ, ЧТО СЕРВИСЫ ПРЕДОСТАВЛЯЮТСЯ «КАК ЕСТЬ», И ЧТО КОМПАНИЯ MAILCHAT INC., ЕЕ ФИЛИАЛЫ, ПОСТАВЩИКИ И
      ПАРТНЕРЫ ПО ПРОДАЖАМ БЕЗОГОВОРОЧНО ОТКАЗЫВАЮТСЯ ОТ ЛЮБЫХ ЯВНО ВЫРАЖЕННЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ ГАРАНТИЙ ЛЮБОГО РОДА,
      ВКЛЮЧАЯ БЕЗ ОГРАНИЧЕНИЙ ЛЮБЫЕ ГАРАНТИИ КОММЕРЧЕСКОГО ПРИМЕНЕНИЯ, ПРИГОДНОСТИ ДЛЯ КОНКРЕТНОЙ ЦЕЛИ ИЛИ НЕНАРУШЕНИЯ
      ПРАВ ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ. КОМПАНИЯ MAILCHAT INC., ЕЕ ФИЛИАЛЫ, ПОСТАВЩИКИ И ПАРТНЕРЫ ПО ПРОДАЖАМ НЕ
      ПРЕДОСТАВЛЯЮТ ГАРАНТИЙ ИЛИ ЗАВЕРЕНИЙ В ОТНОШЕНИИ РЕЗУЛЬТАТОВ, КОТОРЫЕ МОГУТ БЫТЬ ПОЛУЧЕНЫ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ
      УСЛУГ, В ОТНОШЕНИИ ТОЧНОСТИ ИЛИ НАДЕЖНОСТИ ЛЮБОЙ ИНФОРМАЦИИ, ПОЛУЧЕННОЙ ПОСРЕДСТВОМ ИСПОЛЬЗОВАНИЯ УСЛУГ, ИЛИ
      СООТВЕТСТВИЯ УСЛУГ ЛЮБЫМ ТРЕБОВАНИЯМ ПОЛЬЗОВАТЕЛЯ, А ТАКЖЕ БЕСПЕРЕБОЙНОСТИ, СВОЕВРЕМЕННОСТИ, НАДЕЖНОСТИ ИЛИ
      БЕЗОШИБОЧНОСТИ. ИСПОЛЬЗОВАНИЕ УСЛУГ ОСУЩЕСТВЛЯЕТСЯ ИСКЛЮЧИТЕЛЬНО НА ВАШ РИСК. ИСПОЛЬЗОВАНИЕ ЛЮБЫХ МАТЕРИАЛОВ И/ИЛИ
      ДАННЫХ, ЗАГРУЖЕННЫХ ИЛИ ПОЛУЧЕННЫХ ИНЫМ ОБРАЗОМ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ УСЛУГ, ОСУЩЕСТВЛЯЕТСЯ НА ВАШ СОБСТВЕННЫЙ
      СТРАХ И РИСК. ВЫ НЕСЕТЕ ИСКЛЮЧИТЕЛЬНУЮ ОТВЕТСТВЕННОСТЬ ЗА ЛЮБОЙ УЩЕРБ, ПРИЧИНЕННЫЙ ВАМ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ
      УСЛУГ. ВЫ НЕСЕТЕ ОТВЕТСТВЕННОСТЬ ЗА ВЕСЬ РИСК, ВЫТЕКАЮЩИЙ ИЗ ИСПОЛЬЗОВАНИЯ ИЛИ РАБОТЫ УСЛУГ. КОМПАНИЯ MAILCHAT
      INC. НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА СОХРАНЕНИЕ ЛЮБОЙ ПОЛЬЗОВАТЕЛЬСКОЙ ИНФОРМАЦИИ ИЛИ КОММУНИКАЦИИ МЕЖДУ
      ПОЛЬЗОВАТЕЛЯМИ. КОМПАНИЯ MAILCHAT INC. НЕ МОЖЕТ ГАРАНТИРОВАТЬ И НЕ ОБЕЩАЕТ ДОСТИЖЕНИЕ КОНКРЕТНЫХ РЕЗУЛЬТАТОВ
      ИСПОЛЬЗОВАНИЯ УСЛУГ. ИСПОЛЬЗОВАНИЕ ОСУЩЕСТВЛЯЕТСЯ НА ВАШ РИСК.</p><p>16. ОСВОБОЖДЕНИЕ ОТ ОТВЕТСТВЕННОСТИ.&nbsp;Вы
      соглашаетесь оградить, обезопасить и освободить компанию Mailchat Inc., ее филиалы, должностных лиц, директоров,
      сотрудников, консультантов, представителей, поставщиков и Партнеров по продажам от всех без исключения претензий
      третьих лиц, ответственности, ущерба и/или затрат (включая, помимо прочего, оплату юридических услуг), вытекающих
      из использования вами Услуг, нарушения вами настоящего Соглашения или посягательства на нарушение или нарушения
      вами или любым другим пользователем вашей учетной записи, любых прав на интеллектуальную собственность или других
      прав любого физического или юридического лица или действующего законодательства.</p><p>17. ОГРАНИЧЕНИЕ
      ОТВЕТСТВЕННОСТИ.&nbsp;В МАКСИМАЛЬНОМ ОБЪЕМЕ, ДОПУСКАЕМОМ ДЕЙСТВУЮЩИМ ЗАКОНОДАТЕЛЬСТВОМ, НИ ПРИ КАКИХ
      ОБСТОЯТЕЛЬСТВАХ КОМПАНИЯ MAILCHAT INC. ИЛИ ЕЕ ФИЛИАЛЫ, ПОСТАВЩИКИ ИЛИ ПАРТНЕРЫ ПО ПРОДАЖАМ НЕ НЕСУТ
      ОТВЕТСТВЕННОСТИ ЗА ЛЮБЫЕ СПЕЦИАЛЬНЫЕ, КОСВЕННЫЕ, НЕПРЯМЫЕ, ШТРАФНЫЕ ИЛИ СЛУЧАЙНЫЕ УБЫТКИ (ВКЛЮЧАЯ БЕЗ ОГРАНИЧЕНИЙ
      УБЫТКИ, СВЯЗАННЫЕ С ПОТЕРЕЙ ПРИБЫЛИ, ПРЕРЫВАНИЕМ ДЕЯТЕЛЬНОСТИ, ПОТЕРЕЙ ДЕЛОВОЙ ИНФОРМАЦИИ ИЛИ ЛЮБОЙ ИНОЙ
      МАТЕРИАЛЬНЫЙ УЩЕРБ ИЛИ УБЫТКИ), ВОЗНИКАЮЩИЕ ИЗ ИСПОЛЬЗОВАНИЯ ИЛИ НЕВОЗМОЖНОСТИ ИСПОЛЬЗОВАНИЯ СЕРВИСОВ ИЛИ
      ПРЕДОСТАВЛЕНИЯ ИЛИ НЕПРЕДОСТАВЛЕНИЯ ТЕХНИЧЕСКИХ ИЛИ ИНЫХ УСЛУГ ПОДДЕРЖКИ, В СВЯЗИ С ДЕЛИКТОМ (ВКЛЮЧАЯ
      НЕБРЕЖНОСТЬ), ДОГОВОРОМ ИЛИ ЛЮБОЙ ДРУГОЙ ПРАВОВОЙ ТЕОРИЕЙ, ДАЖЕ ЕСЛИ КОМПАНИЯ MAILCHAT INC., ЕЕ ФИЛИАЛЫ,
      ПОСТАВЩИКИ ИЛИ ПАРТНЕРЫ ПО ПРОДАЖАМ РАСПОЛАГАЛИ ИНФОРМАЦИЕЙ О ВОЗМОЖНОСТИ ТАКИХ УБЫТКОВ. ПРИ ЛЮБЫХ ОБСТОЯТЕЛЬСТВАХ
      ОБЩИЙ ОБЪЕМ ОТВЕТСТВЕННОСТИ КОМПАНИИ MAILCHAT INC., ЕЕ ФИЛИАЛОВ, ПОСТАВЩИКОВ И ПАРТНЕРОВ ПО ПРОДАЖАМ И ВАШЕ
      ИСКЛЮЧИТЕЛЬНОЕ СРЕДСТВО ЮРИДИЧЕСКОЙ ЗАЩИТЫ В ОТНОШЕНИИ ЛЮБЫХ ПРЕТЕНЗИЙ, ВЫТЕКАЮЩИХ ИЗ НАСТОЯЩЕГО СОГЛАШЕНИЯ ИЛИ
      СВЯЗАННЫХ С НИМ, ОГРАНИЧИВАЕТСЯ СУММОЙ, ФАКТИЧЕСКИ УПЛАЧЕННОЙ ВАМИ ЗА УСЛУГИ (ЕСЛИ ТАКОВАЯ БЫЛА УПЛАЧЕНА) ЗА 12
      (ДВЕНАДЦАТЬ) МЕСЯЦЕВ, ПРЕДШЕСТВУЮЩИХ СОБЫТИЮ ИЛИ ОБСТОЯТЕЛЬСТВАМ, ПРИВЕДШИМ К ВОЗНИКНОВЕНИЮ ТАКИХ ПРЕТЕНЗИЙ.
      Поскольку некоторые штаты и административно-территориальные образования не допускают исключения или ограничения
      ответственности, вышеуказанное ограничение может быть к вам неприменимо.</p><p>18. СОГЛАСИЕ НА РАССМОТРЕНИЕ СПОРОВ
      В АРБИТРАЖНОМ СУДЕ; ОТКАЗ ОТ КОЛЛЕКТИВНЫХ ИСКОВ.&nbsp;Если вы находитесь на территории США, вы соглашаетесь на
      разрешение споров исключительно на индивидуальной основе в ходе арбитражного делопроизводства в соответствии с
      положениями Приложения А.&nbsp;Стороны явным образом отказываются от любых прав подачи судебных исков, споров или
      процессов в качестве групповых или коллективных исков, исков с участием поверенных или иных судебных процессов, в
      которых какая-либо из сторон выступает в качестве представителя или подразумевает свое участие в этой роли.</p>
      <p>19. КОНФИДЕНЦИАЛЬНОСТЬ И ПРОЧИЕ ПОЛИТИКИ.&nbsp;Компания Mailchat Inc. будет собирать и (или) обрабатывать
        персональные данные в соответствии со своим&nbsp;Заявлением о конфиденциальности. Если вы являетесь владельцем
        учетной записи с тарифным планом «Бизнес» или «Предприятие» и для использования вами Услуг требуется, чтобы
        Компания Mailchat Inc. обрабатывала персональные данные ваших Конечных пользователей в соответствии с
        соглашением об обработке данных. Кроме того, вы осознаете и соглашаетесь с тем, что Компания Mailchat Inc. может
        связываться с вами по электронной почте или иным образом для предоставления информации, связанной с
        использованием вами Услуг, вне зависимости от факта вашей отписки от получения маркетинговых коммуникаций или
        уведомлений.</p><p>20. ПРОЧИЕ ПОЛОЖЕНИЯ</p><p>a. Выбор права и юрисдикции.&nbsp;Настоящее соглашение
      регулируется и толкуется в соответствии с правом штата Калифорния, США, на основании соглашений, заключаемых и
      исполняемых на территории штата Калифорния жителями данного штата. За исключением положений Приложения А, Стороны
      выражают согласие на подчинение исключительной юрисдикции и решениям судов штата, расположенных и действующих в
      округе Санта-Клара, штат Калифорния, и федеральных судов в Северном федеральном округе штата Калифорния.</p><p>b.
      Отказ от прав и автономность положений.&nbsp;Неосуществление любой из сторон любых ее прав в рамках настоящего
      соглашения или для принудительного применения любого его положения не считается отказом или утратой таких прав или
      возможности применения такого положения. Если любое положение настоящего Соглашения признается судом компетентной
      юрисдикции незаконным, недействительным или не имеющим законной силы, такое положение будет изменено для
      максимального приближения к экономическому эффекту первоначального положения, а остальные положения настоящего
      соглашения сохраняют полную силу.</p><p>c. Общие положения.&nbsp;Настоящее соглашение представляет собой полный
      объем договоренностей между сторонами в отношении предмета настоящего соглашения и заменяет все без исключения
      договоренности и соглашения между сторонами в отношении такого предмета, за исключением случаев, когда вами или
      вашей компанией было заключено отдельное письменное соглашение или вы подписали форму заказа со ссылкой на
      отдельное соглашение, регламентирующее использование вами сервисов; при этом такое соглашение действует в тех
      случаях, когда любое положение настоящего соглашения конфликтует с условиями такого соглашения. Компания Mailchat
      Inc. может периодически вносить изменения или дополнения в условия настоящего Соглашения по своему усмотрению.
      Компания Mailchat Inc. обязуется предпринять целесообразные с точки зрения бизнеса действия по предоставлению вам
      уведомлений о внесении любых существенных изменений в настоящее Соглашение. В течение 10 (десяти) рабочих дней с
      момента внесения изменений в настоящее Соглашение или в течение 10 (десяти) рабочих дней с даты уведомления, при
      его наличии, они приобретают для вас обязательную силу. Если вы не согласны с изменениями, вам следует прекратить
      использование Услуг. Если вы продолжите использовать Услуги по истечении десяти рабочих дней, будет считаться, что
      вы принимаете изменения условий настоящего Соглашения. Для участия в работе определенных Услуг вы можете получить
      уведомление о том, что вам необходимо загрузить программное обеспечение и/или принять дополнительные условия и
      положения. Если в дополнительных условиях и положениях явно не указано иное, такие дополнительные условия
      настоящим включаются в настоящее Соглашение. Настоящее соглашение составлено на английском языке, и версия на этом
      языке имеет определяющее значение во всех отношениях, а версии настоящего соглашения на любых других языках
      предназначены исключительно для удобства.</p><p></p><p></p><p>Приложение А</p><p>Обязательный арбитраж</p>
      <p>Настоящее Приложение А к Условиям обслуживания определяет дополнительные положения, применяющиеся к
        Обязательному арбитражу и Отказу от коллективных исков.</p><p>&nbsp;. Споры.&nbsp;Споры&nbsp;— это любые
      разногласия, возникшие между вами и компанией Mailchat Inc., в отношении Услуг, программного обеспечения,
      связанного с Услугами, стоимости Услуг, вашей учетной записи, рекламы, маркетинга и коммуникации компании Mailchat
      Inc., ваших транзакций при покупках или оплате услуг либо в отношении любого термина, используемого в рамках
      настоящего Соглашения, в соответствии с любой теорией права, в том числе с договором, гарантией, деликтом,
      правовыми нормами или нормативными актами, за исключением споров, связанных с применением ваших прав
      интеллектуальной собственности или таких прав компании Mailchat Inc. В части применения максимальных усилий в
      целях разрешения споров и перед началом арбитражного разбирательства каждая из сторон соглашается направить другой
      стороне уведомление о споре, в котором содержится описание спора, усилий, предпринятых для его разрешения, и
      требуемых стороной-инициатором мер разрешения спора, на адрес&nbsp;legal@lenzaos.com .</p><p>A. Доступность суда
      малых исков.&nbsp;Вы можете подать иск в местный суд малых исков, если он соответствует требованиям такого суда.
      Однако в случае передачи, отзыва или апелляции в другой суд Компания Mailchat Inc. оставляет за собой право
      требовать арбитражного разбирательства.</p><p>B. Процедура арбитража.&nbsp;Споры, не разрешенные в соответствии с
      положениями разделов А и Б, должны быть разрешены в ходе арбитражного разбирательства. Арбитражные разбирательства
      будут осуществляться Американской арбитражной ассоциацией (далее&nbsp;— «AAA») в соответствии с ее Правилами
      торгового арбитража. Дополнительную информацию см. на веб-сайте&nbsp;www.adr.org. Арбитражные слушания будут
      проходить в федеральном судебном округе по основному месту расположения вашего бизнеса. Будет назначен единоличный
      арбитр. Арбитр должен: (а) следовать всем применимым законам материального права; (б) следовать применимым законам
      об исковой давности; (в) считаться с действительными требованиями о неразглашении информации; (г) выдать решение
      арбитража в письменном виде и указать причину принятого решения. Арбитр может присудить возмещение ущерба, вынести
      декларативное решение или наложить судебный запрет, а также взыскать издержки (в частности, разумный адвокатский
      гонорар). Все решения арбитражного суда могут быть принудительно исполнены (например, посредством судебного
      решения) любым судом, обладающим соответствующей юрисдикцией.&nbsp;Согласно правилам AAA арбитр выносит решение в
      своей юрисдикции, в том числе с учетом арбитрабельности любого иска; однако суд обладает исключительными
      полномочиями для принудительного применения запрета на арбитражное разбирательство коллективных исков и исков с
      участием представителя.</p><p>C. Арбитражные сборы.&nbsp;Если вы не в состоянии уплатить арбитражные сборы,
      Компания Mailchat Inc. авансирует вам эти расходы, определяемые арбитром, если расходы должны быть возмещены
      компании Mailchat Inc. по принятому судебному решению. В случае споров, связанных с суммами, превышающими
      75&nbsp;000&nbsp;долл. США, Правила AAA регламентируют уплату регистрационного сбора, а также сборов и издержек
      AAA и арбитра.</p><p>D. Разногласия с Правилами ААА.&nbsp;Настоящее соглашение регламентирует наличие противоречий
      с Правилами торгового арбитража AAA.</p><p>E. Требование к подаче иска в течение одного года.&nbsp;Вне зависимости
      от прочих законов об исковой давности, иск или спор в рамках настоящего соглашения должен быть подан в суд малых
      исков или зарегистрирован в арбитражном суде в течение одного года с даты, когда он мог быть зарегистрирован
      впервые, в противном случае такой иск будет признан необратимо погашенным исковой давностью.</p><p>F. Автономность
      положений.&nbsp;Если отказ от коллективных исков будет признан незаконным или неисполнимым в отношении спора в
      целом или какой-либо его части, такие части будут исключены из арбитражного разбирательства и будут разрешены в
      суде. Если какое-либо положение настоящего Приложения А будет признано незаконным или неисполнимым, такое
      положение будет исключено из текста Приложения; однако остальные положения сохранят свою законную силу и будут
      трактоваться как можно ближе для достижения первоначального смысла настоящего Приложения с учетом исключенного
      положения.</p>
    </Dialog>
  )
}

export default DialogPrivacy
