import React, { ReactElement, useEffect } from 'react'
import Main from '../components/Main/Main'
import { Navigate, Route, Routes } from 'react-router-dom'
import InviteInvalid from '../components/InviteInvalid/InviteInvalid'
import { Cookies } from 'react-cookie'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { getOs } from '../shared/lib/invorkers'
import { setOs } from '../state/slices/ui'
import { useDispatch } from 'react-redux'
import {
  ApplicationStorage,
  config,
  Keyboard,
  LangSystem,
} from '@clout-team/web-components'
import { initColorMode, initTheme, isInnerIframe } from '../shared/lib/functions'
import '../shared/config/i18n/getInitInitialLang'

const cookies = new Cookies()

const App = (): ReactElement => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isElectron()) {
      getOs().then((res: string) => dispatch(setOs(res)))
    }

    const isIframe = isInnerIframe()

    if (isIframe) {
      const root = document.getElementById('root')
      if (root && !root.classList.contains('is-inner-iframe')) {
        root.classList.add('is-inner-iframe')
      }
    }

    const onChangeMessage = (event: MessageEvent<{ type: string; value: any }>) => {
      try {
        console.log('window message', event.data)

        if (event.data.type === 'change-color-mode') {
          initColorMode(event.data.value)
        }

        if (event.data.type === 'change-theme') {
          initTheme(event.data.value)
        }

        if (event.data.type === 'init-safe-zones') {
          document.documentElement.style.setProperty(
            '--safe-area-bottom',
            event.data.value.bottom,
          )
          document.documentElement.style.setProperty(
            '--safe-area-top',
            event.data.value.top,
          )
        }

        if (
          event.data.type === config.windowsMessagesKeys.KEYBOARD_SET_STATE_MESSAGE_KEY
        ) {
          Keyboard.setState(event.data.value.active)
          if (event.data.value.active) {
            document.documentElement.style.setProperty(
              '--keyboard-height',
              `${event.data.value.height}px`,
            )
            Keyboard.keyboardHeight = event.data.value.height
          } else {
            Keyboard.keyboardHeight = 0
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    window.addEventListener('message', onChangeMessage)

    return () => {
      window.removeEventListener('message', onChangeMessage)
    }
  }, [])

  useEffect(() => {
    return LangSystem.subscribe(() => {
      const lang: string = cookies.get('lang')
      // @ts-ignore
      if (LangSystem.currentLanguage !== lang && LangSystem.languageList[lang]) {
        LangSystem.changeLang(lang)
      }
    })
  }, [])

  return (
    <ApplicationStorage>
      <Routes>
        <Route path='/:locale/:path' element={<Main />} />
        <Route path='/:locale' element={<Main />} />
        {/*<Route path='/' element={<Navigate to={getDefaultLang()} />} />*/}
        <Route path='*' element={<InviteInvalid />} />
      </Routes>
    </ApplicationStorage>
  )
}

export default App
