import React, { useState } from 'react'
import { Text } from '@clout-team/web-components'
import { useSelector } from 'react-redux'
import DialogAgreement from '../DialogAgreement/DialogAgreement'
import DialogPrivacy from '../DialogPrivacy/DialogPrivacy'
import './Privacy.scss'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

interface PrivacyProps {
  className?: string
  align?: string
}

const Privacy = (props: PrivacyProps) => {
  const { className, align = 'center' } = props

  const { t } = useTranslation()

  const [dialogPrivacy, setDialogPrivacy] = useState(false)
  const [dialogAgree, setDialogAgree] = useState(false)

  return (
    <div className={classNames(className, 'auth-privacy')}>
      {dialogAgree && <DialogAgreement L={t} onClose={() => setDialogAgree(false)} />}
      {dialogPrivacy && <DialogPrivacy L={t} onClose={() => setDialogPrivacy(false)} />}
      <Text align={align} className={'text-privacy'} type={'secondary'} size={'footnote'}>
        {t('auth.privacy.start')}{' '}
        <span className={'auth-privacy-link'} onClick={() => setDialogAgree(true)}>
          {t('auth.privacy.user.agree')}{' '}
        </span>{' '}
        {t('auth.privacy.and.the')}{' '}
        <span className={'auth-privacy-link'} onClick={() => setDialogPrivacy(true)}>
          {t('auth.privacy.user.privacy')}
        </span>
      </Text>
    </div>
  )
}

export default Privacy
