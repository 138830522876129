import { Button, config, Dialog, Input } from '@clout-team/web-components'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import './SendMessageModal.scss'
import { useTranslation } from 'react-i18next'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import classNames from 'classnames'

interface ISendMessageModal {
  onClose: () => void
  onSubmit: (text: string) => void
}

export const SendFirstMessageModal: React.FC<ISendMessageModal> = ({
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const [isDisabled, setIsDisabled] = useState(true)

  const name = useAppSelector((state) => state.login.name)
  const surname = useAppSelector((state) => state.login.surname)

  const [value, setValue] = useState<string>(
    t('auth.new.friends.modal.input_default')?.replace(
      '{__name__}',
      `${name} ${surname}`,
    ),
  )

  useEffect(() => {
    if (value.length > 0) {
      setIsDisabled(false)
    } else setIsDisabled(true)
  }, [value])

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const onSubmitHandler = () => {
    onSubmit(value)
  }

  return (
    <Dialog
      classNameTitle={'mobile-header '}
      onClose={onClose}
      className={classNames('send-first-message-for-friends')}
      dialogTitle={{
        noSwipeOnClose: true,
        title: `${t('auth.new.friends.modal.title')} 💬`,
        withOutPadding: false,
      }}
      dialogButtons={
        config.IS_MOBILE
          ? {
              center: (
                <ButtonUI
                  fullWidth
                  size='dialog-mobile-lg'
                  className={classNames('send-first-message-for-friends_submit')}
                  disabled={isDisabled}
                  key='forward-modal-cancel-btn'
                  onClick={onSubmitHandler}
                >
                  {t('auth.new.send')}
                </ButtonUI>
              ),
              isKeyboardUpButton: true,
            }
          : {
              right: [
                <Button
                  size='lg'
                  color='white'
                  inverted
                  key='forward-modal-cancel-btn'
                  onClick={onClose}
                >
                  {t('auth.new.cancel')}
                </Button>,
                <Button
                  size='lg'
                  disabled={isDisabled}
                  key='forward-modal-cancel-btn'
                  onClick={onSubmitHandler}
                >
                  {t('auth.new.send')}
                </Button>,
              ],
            }
      }
    >
      <p className={'modal__description'}>{t('auth.new.friends.modal.description')}</p>
      <p className={'modal__form_title'}>{t('auth.new.friends.modal.input_title')} </p>

      <form>
        <Input
          className={'inp_auth_input'}
          type='textarea'
          height='lg'
          showMaxLengthCounter={value.length === 256}
          maxLength={256}
          value={value}
          onChange={onChangeHandler}
        />
      </form>
    </Dialog>
  )
}
