import { getUrlParamByKey } from '@clout-team/helpers/dist/utils/web-utils'
import { Cookies } from 'react-cookie'
import { LANG_ARR } from '../../const/lang'
import { initLang } from './initLang'
import { getSystemLang } from './getSystemLang'

const cookies = new Cookies()

export function getInitialLang() {
  let initialLang = getSystemLang()
  let urlLang = ''

  const url = document.location.pathname.split('/')

  if (url[1] && LANG_ARR.includes(url[1])) urlLang = url[1]

  const langFromCookie = cookies.get('lang')

  const langFromParam = getUrlParamByKey('lang') // для приглашения

  if (langFromParam && LANG_ARR.indexOf(langFromParam) > -1) {
    initialLang = langFromParam
  } else if (urlLang !== '' && LANG_ARR.indexOf(urlLang) > -1) {
    initialLang = urlLang
  } else if (langFromCookie && LANG_ARR.indexOf(langFromCookie) > -1) {
    initialLang = langFromCookie
  }

  initLang(initialLang)

  return initialLang
}
